import { useCallback, useMemo, useState } from 'react';
import { FlexAlignCenter, FlexCol } from 'components/primitives/flex';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import styled, { css } from 'styled-components';
import { useBreakpoint } from 'components/BreakpointProvider';
import isEmpty from 'lodash/isEmpty';
import { BoatRules } from 'swagger/models';
import CollapsibleHeader from './CollapsibleHeader';
import { ContainerGrid1to4, Separator, SubHeading, ToggleLink } from './common';
import { CheckIcon } from './icons';
import { useBoatDetails } from './hooks';

const DESKTOP_MAX_RULES_VISIBLE = 3 * 5;
const MOBILE_MAX_RULES_VISIBLE = 5;

type Props = {
  boatRules: BoatRules;
  cancellationPolicy: string;
  captainNetworkEnabled: boolean;
  isBoatCommercial: boolean;
  boatId: string;
};

const ThingsToKnow = ({
  boatRules = {},
  cancellationPolicy,
  captainNetworkEnabled,
  isBoatCommercial,
  boatId,
}: Props) => {
  const [allRulesVisible, setAllRulesVisible] = useState(false);
  const toggleRules = useCallback(() => setAllRulesVisible((areRulesVisible) => !areRulesVisible), []);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const maxRulesCount = smallScreen ? MOBILE_MAX_RULES_VISIBLE : DESKTOP_MAX_RULES_VISIBLE;
  const boatRulesEntries = Object.entries(boatRules).filter(([, allowed]) => allowed);
  const slicedBoatRules = useMemo(
    () => boatRulesEntries.slice(0, allRulesVisible ? -1 : maxRulesCount),
    [allRulesVisible, maxRulesCount, boatRulesEntries]
  );
  const { boatDetails } = useBoatDetails(boatId);

  if (isEmpty(boatDetails)) {
    return null;
  }

  const isModerateCancellation = cancellationPolicy === 'moderate';
  const isStrictCancellation = cancellationPolicy === 'strict';
  return (
    <ContainerGrid1to4 data-testid="pdp-things-to-know">
      <div>
        <SubHeading>Things to know</SubHeading>
      </div>
      <FlexCol>
        <CollapsibleHeader headerContent={<CollapsibleHeading>Allowed on boat</CollapsibleHeading>} openByDefault>
          <RulesContainer
            rowsCount={Math.ceil(slicedBoatRules.length / 3)}
            data-testid="pdp-things-to-know-allowed-on-boat-box"
          >
            {slicedBoatRules.map(
              ([ruleName, allowed]) =>
                allowed && (
                  <AllowedItem key={ruleName} gap="15px">
                    <CheckIcon />
                    <RuleName data-testid="pdp-things-to-know-allowed-on-boat-rule">
                      {ruleName.replace(/_/g, ' ')}
                    </RuleName>
                  </AllowedItem>
                )
            )}
          </RulesContainer>
          {boatRulesEntries.length > maxRulesCount && (
            <ToggleLink onClick={toggleRules}>{allRulesVisible ? 'Show less' : 'Show more'}</ToggleLink>
          )}
        </CollapsibleHeader>
        <Separator />
        <CollapsibleHeader
          headerContent={
            <CollapsibleHeading>
              Cancellation policy{' '}
              <Badge moderate={isModerateCancellation} strict={isStrictCancellation}>
                {cancellationPolicy}
              </Badge>
            </CollapsibleHeading>
          }
        >
          <CancellationList data-testid="pdp-things-to-know-cancellation-policy-list">
            {boatDetails?.boat_cancellation_text.map((policy) => (
              <li key={policy}>{policy}</li>
            ))}
          </CancellationList>
          <StyledLink target="_blank" rel="noopener noreferrer" href="/legal/cancellation_policy">
            See policy
          </StyledLink>
        </CollapsibleHeader>
        {isBoatCommercial && (
          <>
            <Separator />
            <CollapsibleHeader headerContent={<CollapsibleHeading>Commercial owner</CollapsibleHeading>}>
              <StyledText>
                This boat belongs to a Commercial Owner, an independent boat rental company. A Commercial Owner is an
                independent, Boatsetter-approved boat rental company who provides their own insurance to their guests.
              </StyledText>
            </CollapsibleHeader>
          </>
        )}
        <Separator />
        <CollapsibleHeader headerContent={<CollapsibleHeading>Security deposit</CollapsibleHeading>}>
          <StyledText>
            A security deposit hold (not a charge) will be placed on your credit card 48 hours before your booking
            starts to cover any incidental damage that may occur during your rental. This hold is released 48 hours
            after the booking is complete, if no claims are made. The security deposit amount for the boat you are
            booking will be outlined during the check-out process.
          </StyledText>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.boatsetter.com/hc/en-us/articles/205348914-Security-deposit"
          >
            Learn more
          </a>
        </CollapsibleHeader>
        {!captainNetworkEnabled && (
          <>
            <Separator />
            <CollapsibleHeader headerContent={<CollapsibleHeading>Captain info</CollapsibleHeading>}>
              <StyledText>
                If requested, the owner can provide a list of available captains for bareboat charter or the renter can
                use their own qualified captain. If the boat is being time chartered, the owner will provide the
                Captain.
              </StyledText>
            </CollapsibleHeader>
          </>
        )}
      </FlexCol>
    </ContainerGrid1to4>
  );
};

export default ThingsToKnow;

const StyledLink = styled.a`
  font-weight: 500;
`;

const AllowedItem = styled(FlexAlignCenter)`
  ${mediaMMdMax} {
    flex-flow: row-reverse;
    justify-content: space-between;
  }
`;

const CollapsibleHeading = styled.h3`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  ${mediaMMdMax} {
    font-size: 16px;
  }
`;

const CancellationList = styled.ul`
  margin: 0;
  list-style: disc inside;

  li {
    margin-bottom: 12px;
  }
`;

const RulesContainer = styled.div<{ rowsCount: number }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px 120px;

  ${mediaMLgMin} {
    grid-auto-flow: column;
    grid-template: repeat(min(${({ rowsCount }) => rowsCount}, 5), 1fr) / repeat(3, 1fr);
    grid-auto-rows: auto;
  }
`;

const RuleName = styled.span`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Badge = styled.span<{ moderate: boolean; strict: boolean }>`
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  text-transform: uppercase;
  line-height: 9px;
  padding: 3px 8px;
  margin-left: 10px;

  ${({ moderate, strict }) => css`
    background-color: ${getBgColor(strict, moderate)};
    color: ${moderate ? '#17233c' : '#fff'};
  `};
`;

const getBgColor = (strict, moderate) => {
  if (strict) {
    return '#bc350a';
  }
  if (moderate) {
    return '#f4c064';
  }
  return '#2a8500';
};

const StyledText = styled.p`
  line-height: 24px;
`;
