import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { BoatCaptainOptionEnum } from 'swagger/models';
import { useAtom, useAtomValue } from 'jotai';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import { bookingBoatIdAtom, selectedBookingPackageAtom } from 'components/forms/bookingWidget/jotaiStore';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';

interface Props {
  captainStatus: BoatCaptainOptionEnum;
  onChange: (e: any) => void;
}

const PackageTypePicker = ({ captainStatus, onChange = null }: Props) => {
  const router = useRouter();
  const rudderTrack = useRudderStackTrack();
  const [selectedBookingPackage, setSelectedBookingPackage] = useAtom(selectedBookingPackageAtom);
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const { boatDetails: bookingBoatDetails } = useBoatDetails(bookingBoatId);

  const selectedPackageType = selectedBookingPackage?.package_type;

  const selectPackageType = (type: string) => () => {
    const packageToSelect = bookingBoatDetails.packages.find((pack) => pack.package_type === type);
    if (selectedBookingPackage && packageToSelect.id !== selectedBookingPackage.id) {
      setSelectedBookingPackage(packageToSelect);

      rudderTrack('selection_package_type', {
        value: packageToSelect.package_type,
      });

      if (onChange) onChange(packageToSelect);

      router.push(
        {
          query: {
            ...router.query,
            package_type: packageToSelect.package_type,
          },
        },
        null,
        { shallow: true, scroll: false }
      );
    }
  };

  const isCaptainedOptionDisabled = captainStatus === BoatCaptainOptionEnum.no_captain_only;
  const isBareboatOptionDisabled = captainStatus === BoatCaptainOptionEnum.captain_only;

  const isCaptainedOptionSelected = selectedPackageType === 'captained';
  const isBareboatOptionSelected = selectedPackageType === 'bareboat';

  return (
    <Wrapper data-testid="pdp-booking-widget-package-type-picker-container">
      <Option
        selected={isCaptainedOptionSelected}
        onClick={isCaptainedOptionDisabled || isCaptainedOptionSelected ? null : selectPackageType('captained')}
        disabled={isCaptainedOptionDisabled}
        data-testid="components-forms-package-type-picker-captained"
      >
        Captained
      </Option>
      <Option
        selected={isBareboatOptionSelected}
        onClick={isBareboatOptionDisabled || isBareboatOptionSelected ? null : selectPackageType('bareboat')}
        disabled={isBareboatOptionDisabled}
        data-testid="components-forms-package-type-picker-bareboat"
      >
        No captain
      </Option>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const Option = styled.div<{ selected: boolean; disabled: boolean }>`
  width: 160px;
  display: flex;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border: 1px solid #cdd1d2;
  border-radius: 8px;

  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: #dbdfe5;
      cursor: not-allowed;
    `}

  ${({ selected }) =>
    selected &&
    css`
      color: #0751c2;
      background: #eef3fb;
      border: none;
      font-weight: 600;
    `}
`;

export default PackageTypePicker;
