import startCase from 'lodash/startCase';
import { useState } from 'react';
import styled from 'styled-components';
import { mediaMMdMax } from 'helpers/breakpoints';
import isEmpty from 'lodash/isEmpty';
import {
  BedsIcon,
  BoatTypeIcon,
  LengthSpecIcon,
  MakeIcon,
  ModelIcon,
  PassengersIcon,
  SleepsIcon,
  StateRoomsIcon,
  YearIcon,
} from './icons';
import { Section, SubHeading, ToggleLink } from './common';
import { Flex, FlexAlignCenter } from '../../primitives/flex';
import { useBoatDetails } from './hooks';

const Specifications = ({ boatId }: { boatId: string }) => {
  const { boatDetails } = useBoatDetails(boatId);
  const [moreSpecifications, setMoreSpecifications] = useState(false);

  if (isEmpty(boatDetails)) return null;

  const specifications = [
    {
      name: 'Year',
      icon: YearIcon,
      value: boatDetails.year_manufactured,
    },
    {
      name: 'Length',
      icon: LengthSpecIcon,
      value: `${boatDetails.length} ft.`,
    },
    {
      name: 'Make',
      icon: MakeIcon,
      value: boatDetails.make,
    },
    {
      name: 'Model',
      icon: ModelIcon,
      value: boatDetails.model,
    },
    {
      name: 'Capacity',
      icon: PassengersIcon,
      value: boatDetails.capacity,
    },
    {
      name: 'Boat type',
      icon: BoatTypeIcon,
      value: boatDetails && startCase(boatDetails.boat_category),
    },
    {
      name: 'Sleeps',
      icon: SleepsIcon,
      value: boatDetails.sleeping_capacity,
    },
    {
      name: 'Staterooms',
      icon: StateRoomsIcon,
      value: boatDetails.private_staterooms,
    },
    {
      name: 'Bed count',
      icon: BedsIcon,
      value: boatDetails.bed_count,
    },
  ];
  const MAX_SPECIFICATIONS = 5;

  return (
    <Section data-testid="pdp-specifications">
      <SubHeading>Specifications</SubHeading>
      <SpecificationContainer>
        {specifications
          .filter((spec) => !!spec.value)
          .slice(0, moreSpecifications ? 100 : MAX_SPECIFICATIONS)
          .map((spec) => (
            <StyledSpecification key={spec.name} data-testid="pdp-specifications-spec">
              <div>
                <SpecificationName>
                  {spec.icon()}
                  <span>{spec.name}</span>
                </SpecificationName>
              </div>
              <SpecificationValue data-testid="pdp-specifications-spec-value">{spec.value}</SpecificationValue>
            </StyledSpecification>
          ))}
      </SpecificationContainer>
      {boatDetails.features.length > MAX_SPECIFICATIONS && (
        <ToggleLink
          onClick={() => setMoreSpecifications(!moreSpecifications)}
          data-testid="pdp-specifications-see-all-link"
        >
          See {moreSpecifications ? 'less' : 'all'}
        </ToggleLink>
      )}
    </Section>
  );
};

const SpecificationContainer = styled.div`
  padding-top: 25px;
  max-width: 480px;
`;

const StyledSpecification = styled(Flex)`
  &:not(:last-child) {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(219, 223, 229, 0.5);
  }

  ${mediaMMdMax} {
    justify-content: space-between;
  }
`;

const SpecificationName = styled(FlexAlignCenter)`
  min-width: 200px;
  gap: 10px;

  svg {
    min-width: 22px;
  }
`;

const SpecificationValue = styled.div`
  font-weight: 600;
  text-align: right;
`;

export default Specifications;
