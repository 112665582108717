import { useEffect } from 'react';
import styled from 'styled-components';
import { FlexCol } from 'components/primitives/flex';
import { useRouter } from 'next/router';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import { useBreakpoint } from 'components/BreakpointProvider';
import { useRudderStackOrderTraits } from 'components/forms/bookingWidget/hooks';
import useModal from 'components/remodal/v2/useModal';
import useIncompleteUserProcessing from 'components/auth/hooks/useIncompleteUserProcessing';
import { useUserDetails } from 'auth/mutations/userHooks';
import Button from 'components/ui/Button/Button';
import { BoatPackage } from 'swagger/models';
import boatsetterPaths from 'utils/boatsetterPaths';
import { ContainerGrid1to4, SubHeading, ToggleLink } from './common';
import { UserCardCrewMember } from './UserCard';
import { useBoatDetails } from './hooks';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';
import MessageOwnerModal from './modals/MessageOwnerModal';
import ChangeCaptainModal from './modals/ChangeCaptainModal';

type Props = {
  // TODO BoatPrimaryManager not complete in swagger
  owner: any;
  // TODO Captain not complete in swagger
  captain: any;
  isBoatCaptained: boolean;
  boatId: string;
  primaryPackage: BoatPackage;
};

export default function CrewMembers({ owner, captain, isBoatCaptained, boatId, primaryPackage }: Props) {
  const router = useRouter();

  const { isAuthenticated } = useUserDetails();
  const { boatDetails } = useBoatDetails(boatId);
  const rudderStackOrderTraits = useRudderStackOrderTraits();

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const rudderTrack = useRudderStackTrack();

  const { handlePossibleIncompleteAccountInApp } = useIncompleteUserProcessing();

  const { modal: messageOwnerModal, openModal: openMessageOwnerModal } = useModal(
    <MessageOwnerModal boatId={boatId} primaryPackage={primaryPackage} />,
    {
      size: 'medium',
      isOnRight: true,
      isOverlay: true,
      hideCloseButton: smallScreen,
      trackingName: 'message_owner_modal',
    }
  );

  const { modal: changeCaptainModal, openModal: openChangeCaptainModal } = useModal(
    <ChangeCaptainModal boatId={boatId} />,
    {
      isOnRight: true,
      notScrollAble: true,
      isTray: false,
      trackingName: 'change_captain_modal',
    }
  );

  useEffect(() => {
    if (router.query.open_captain_modal === 'true' && isAuthenticated) {
      openChangeCaptainModal();
    }
  }, [isAuthenticated, router.query.open_captain_modal]);

  useEffect(() => {
    if (router.isReady && router.query.open_message_modal === 'true' && isAuthenticated) {
      handleOpenMessageOwnerModal(null, true);
    }
  }, [isAuthenticated, router.query.open_message_modal, router.isReady]);

  const handleOpenMessageOwnerModal = async (_event, openedByUrl = false) => {
    const alreadyHasOpenMessageModal = router.asPath.includes('open_message_modal=true');
    if (isAuthenticated) {
      const redirectTo = `${router.asPath}${router.asPath.includes('?') ? '&' : '?'}${
        alreadyHasOpenMessageModal ? '' : 'open_message_modal=true'
      }`;

      const result = await handlePossibleIncompleteAccountInApp(redirectTo);

      if (result === false) {
        return;
      }

      openMessageOwnerModal();

      rudderTrack('message_owner_start', {
        source: openedByUrl ? 'url' : 'button',
        ...rudderStackOrderTraits,
      });
    } else {
      router.push({
        pathname: boatsetterPaths.signUp,
        query: {
          redirect_to: `${router.asPath}${router.asPath.includes('?') ? '&' : '?'}${
            alreadyHasOpenMessageModal ? '' : 'open_message_modal=true'
          }`,
        },
      });
    }
  };

  const renderCaptain = boatDetails.captain_network_enabled && isBoatCaptained && captain;

  return (
    <ContainerGrid1to4>
      <SubHeading>Your crew</SubHeading>

      <FlexCol gap="52px">
        <MemberRow data-testid="pdp-crew-memeber-owner-box">
          <MemberRole>Boat owner</MemberRole>
          <UserCardCrewMember
            profilePhotoUrl={owner?.picture.url}
            text={owner?.profile_text}
            userId={owner?.id}
            rating={owner?.rating}
            reviewCount={owner?.review_count}
            memberName={owner?.first_name}
            isTopOwner={boatDetails.primary_manager?.top_owner_badge}
            isNewOwner={boatDetails.primary_manager?.new_owner_badge}
          />
          <div>
            <StyledList>
              <li data-testid="pdp-your-crew-owner-response-rate">
                Response rate: <Bold>{owner?.response_rate}</Bold>
              </li>
              <li data-testid="pdp-your-crew-owner-response-time">
                Avg. response time: <Bold>{owner?.response_time}</Bold>
              </li>
            </StyledList>
            <div data-testid="pdp-your-crew-message-owner">
              <Button
                outline
                onClick={handleOpenMessageOwnerModal}
                text="MESSAGE OWNER"
                className="messageOwnerButton"
              />
            </div>
          </div>
        </MemberRow>
        {renderCaptain && <Separator />}
        {renderCaptain && (
          <MemberRow data-testid="pdp-crew-memeber-captain-box">
            <MemberRole>Your captain</MemberRole>
            <UserCardCrewMember
              profilePhotoUrl={captain.user.picture.url}
              text={captain.user.profile_text}
              userId={captain.user.id}
              rating={captain.user.reviews.rate}
              reviewCount={captain.user.reviews.count}
              memberName={captain.user.first_name}
              isNewCaptain={captain.new_captain_badge}
            />
            <div>
              <StyledList>
                <li data-testid="pdp-your-crew-captain-trips-completed">
                  Trips completed: <Bold>{captain.trips_completed}</Bold>
                </li>
                <li>
                  US Coast Guard: <Bold>Verified</Bold>
                </li>
              </StyledList>
              <StyledToggleLink onClick={openChangeCaptainModal} data-testid="pdp-your-crew-change-captain">
                Change captain
              </StyledToggleLink>
            </div>
          </MemberRow>
        )}
      </FlexCol>

      {messageOwnerModal}
      {changeCaptainModal}
    </ContainerGrid1to4>
  );
}

const Bold = styled.span`
  font-weight: 700;
`;

const Separator = styled.hr`
  display: none;
  ${mediaMMdMax} {
    display: block;
  }
  height: 1px;
  width: 100%;
  background-color: #dbdfe5;
  border: none;
  //margin: 36px 0 30px;
`;

const MemberRow = styled(FlexCol)`
  gap: 12px;

  .messageOwnerButton {
    padding: 0 22px;

    ${mediaMMdMax} {
      width: 100%;
    }
  }

  ${mediaMLgMin} {
    display: grid;
    gap: 16px 96px;
    grid-template-columns: repeat(2, 2fr);
  }
`;

const MemberRole = styled.div`
  grid-column: span 2;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
`;

const StyledList = styled.ul`
  margin-bottom: 16px;

  ${mediaMMdMax} {
    margin-bottom: 25px;
  }

  li {
    margin-bottom: 10px;
  }
`;

const StyledToggleLink = styled(ToggleLink)`
  padding: 0;
`;
