import { useMemo } from 'react';
import styled from 'styled-components';
import BookingForm from 'components/forms/bookingWidget/BookingForm';
import BookingTerms from 'components/forms/bookingWidget/BookingTerms';
import CancellationInfo from 'components/forms/bookingWidget/CancellationInfo';
import { Box } from 'components/primitives/box';
import dayjs from 'dayjs';
import { mediaMSmMax } from 'helpers/breakpoints';
import { selectedBookingPackageAtom } from 'components/forms/bookingWidget/jotaiStore';
import { useAtomValue } from 'jotai';
import OutstandingOffer from 'components/forms/bookingWidget/OutstandingOffer';
import { useTripCalculator } from 'hooks/queries';
import { BoatPackage } from 'swagger/models';
import isEmpty from 'lodash/isEmpty';
import { Separator } from '../common';
import { useBoatDetails } from '../hooks';

export default function MobileBookingModal({
  boatId,
  primaryPackage,
}: {
  boatId: string;
  primaryPackage: BoatPackage;
}) {
  const { boatDetails } = useBoatDetails(boatId);
  const { tripCalculation } = useTripCalculator();

  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);

  const showFreeCancellation = useMemo(
    () =>
      tripCalculation?.free_cancelation_until &&
      dayjs(tripCalculation?.free_cancelation_until).diff(dayjs(), 'day') !== 0,
    [tripCalculation?.free_cancelation_until]
  );

  if (isEmpty(boatDetails)) {
    return null;
  }

  return (
    <Box
      smMax={{
        pt: '60px',
      }}
    >
      <StyledOutstandingOffer boatId={boatId} />
      <Heading>Request to book</Heading>
      <Subheading>Enter your booking details</Subheading>
      <BookingForm boatId={boatId} primaryPackage={primaryPackage} isExpanded isModal setExpanded={() => {}} />

      <TermsAndCancellationContainer>
        {showFreeCancellation && <CancellationInfo isModal />}
        {showFreeCancellation && <StyledSeparator />}
        <BookingTerms
          boatId={boatId}
          securityDepositCents={selectedBookingPackage?.security_deposit_cents}
          cancellationPolicy={boatDetails.cancellation_policy}
          fuelPolicy={selectedBookingPackage?.fuel_policy}
        />
      </TermsAndCancellationContainer>
    </Box>
  );
}

const StyledOutstandingOffer = styled(OutstandingOffer)``;

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -6px;

  ${mediaMSmMax} {
    line-height: 1;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.08);
  }
`;

const Subheading = styled.div`
  font-weight: 600;
  padding-bottom: 20px;
`;

const TermsAndCancellationContainer = styled(Box)`
  background: rgba(245, 247, 249, 0.78);
  margin: 0 -1.5em;
  padding: 24px 24px 120px;
`;

const StyledSeparator = styled(Separator)`
  margin: 16px 0 10px;
`;
