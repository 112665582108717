import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'components/ui/Button/Button';
import { Box } from 'components/primitives/box';
import { FlexCol } from 'components/primitives/flex';
import useFormWithErrorTracking from 'hooks/useFormWithErrorTracking';
import { useCloseModal } from 'components/remodal/v2/hooks';

export default function ShareMailForm({ boatLocation, makeAndModel, className = '', boatUrl }) {
  const closeModal = useCloseModal();
  const methods = useFormWithErrorTracking({
    mode: 'onSubmit',
  });

  const [message] = useState(`Check out this great boat on Boatsetter: ${makeAndModel} in ${boatLocation}!`);

  const processFormData = async () => {
    window.location.href = `mailto:?subject=Check out this boat I found on Boatsetter.&body=${message} ${encodeURIComponent(
      boatUrl
    )}`;
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit(processFormData)}
        className={className}
        data-testid="pdp-share-email-form"
      >
        <FlexCol>
          <StyledLabel>Message</StyledLabel>
          <Box margin="8px 0 32px" overflowWrap="break-word">
            {`${message} ${boatUrl}`}
          </Box>
          <Button text="Send message" type="submit" id="submitFormButton" />
        </FlexCol>
      </form>
    </FormProvider>
  );
}

const StyledLabel = styled(Box)`
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #0a252f;
`;
