import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import { FlexAlignCenterSpaceBetween } from 'components/primitives/flex';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import { ArrowDownCollapseIcon } from './icons';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';
import { mediaMMdMax } from '../../../helpers/breakpoints';

type Props = PropsWithChildren<{
  headerContent: ReactNode;
  arrowColor?: string;
  openByDefault?: boolean;
}>;

export default function CollapsibleHeader({
  headerContent,
  children,
  arrowColor = '#0751C2',
  openByDefault = false,
}: Props) {
  const rudderTrack = useRudderStackTrack();
  const ref = useRef();

  const track = (action) => () => {
    rudderTrack('page_collapsible_click', {
      action,
      // @ts-ignore
      heading: ref?.current?.innerText,
    });
  };

  return (
    <Collapsible
      open={openByDefault}
      onTriggerOpening={track('open')}
      onTriggerClosing={track('close')}
      trigger={
        <Header ref={ref} data-testid="component-collapsible-header" className="CollapsibleHeader-header">
          {headerContent}
          <CollapseArrow $arrowColor={arrowColor} data-testid="arrow-down-collapse-icon" />
        </Header>
      }
    >
      <CollapsibleWrapper>{children}</CollapsibleWrapper>
    </Collapsible>
  );
}

const CollapsibleWrapper = styled.div`
  padding-top: 15px;
  ${mediaMMdMax} {
    padding-top: 25px;
  }
`;

const Header = styled(FlexAlignCenterSpaceBetween)`
  cursor: pointer;
`;

const CollapseArrow = styled(ArrowDownCollapseIcon)`
  path {
    fill: ${({ $arrowColor }) => $arrowColor};
  }

  .Collapsible__trigger & {
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .Collapsible__trigger.is-open & {
    transform: rotate(180deg);
  }
`;
