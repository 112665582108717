import { Flex } from 'components/primitives/flex';
import { mediaMMdMax } from 'helpers/breakpoints';
import { dollarsNoCentsWithSymbol } from 'helpers/currency';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { BoatCaptainOptionEnum, BoatPackage } from 'swagger/models';
import { tripStartFormattedAtom } from 'components/forms/bookingWidget/jotaiStore';
import { useAtomValue } from 'jotai';
import { SubHeading } from './common';
import { useBoatDatePricing, useBoatDetails } from './hooks';
import { FuelIcon } from './icons';

type Props = {
  packages: BoatPackage[];
  primaryPackage: BoatPackage;
  boatId: string;
};

export default function BookingOptions({ packages, primaryPackage, boatId }: Props) {
  const [bookingOption, setBookingOption] = useState(primaryPackage);

  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);

  const { boatDetails } = useBoatDetails(boatId);
  const { boatDatePricing, isIdle } = useBoatDatePricing(boatId, tripStartFormatted);

  const switchBookingOption = (option: string) => () => {
    setBookingOption(packages.filter((pack) => pack.package_type === option)[0]);
  };

  if (!primaryPackage || isIdle) return null;

  const datePricingCurrentPackage =
    bookingOption?.package_type === 'bareboat' ? boatDatePricing?.bareboat : boatDatePricing?.captained;

  return (
    <Container id="bookingOptions">
      <SubHeading>Booking options</SubHeading>
      <BookingOptionsContainer>
        <BookingHeader data-testid="pdp-booking-options-package-types">
          {[BoatCaptainOptionEnum.no_captain_only, BoatCaptainOptionEnum.captain_optional].includes(
            // @ts-ignore
            boatDetails.captain_option
          ) && (
            <BookingOptionCategory
              onClick={switchBookingOption('bareboat')}
              active={bookingOption?.package_type === 'bareboat'}
              data-testid="pdp-booking-options-package-types-bareboat"
            >
              No captain
            </BookingOptionCategory>
          )}

          {[BoatCaptainOptionEnum.captain_only, BoatCaptainOptionEnum.captain_optional].includes(
            // @ts-ignore
            boatDetails.captain_option
          ) && (
            <BookingOptionCategory
              onClick={switchBookingOption('captained')}
              active={bookingOption?.package_type === 'captained'}
              data-testid="pdp-booking-options-package-types-captained"
            >
              Captained
            </BookingOptionCategory>
          )}
        </BookingHeader>
        <BookingOptionContent data-testid="pdp-booking-options-package-durations-and-price">
          {!!datePricingCurrentPackage?.two_hours && (
            <BookingOption>
              <BookingOptionDuration>2 hours</BookingOptionDuration>
              <BookingOptionPrice>{dollarsNoCentsWithSymbol(datePricingCurrentPackage.two_hours)}</BookingOptionPrice>
            </BookingOption>
          )}

          {!!datePricingCurrentPackage?.three_hours && (
            <BookingOption>
              <BookingOptionDuration>3 hours</BookingOptionDuration>
              <BookingOptionPrice>{dollarsNoCentsWithSymbol(datePricingCurrentPackage.three_hours)}</BookingOptionPrice>
            </BookingOption>
          )}

          {!!datePricingCurrentPackage?.half_day && (
            <BookingOption>
              <BookingOptionDuration>4 hours</BookingOptionDuration>
              <BookingOptionPrice>{dollarsNoCentsWithSymbol(datePricingCurrentPackage.half_day)}</BookingOptionPrice>
            </BookingOption>
          )}

          {!!datePricingCurrentPackage?.six_hours && (
            <BookingOption>
              <BookingOptionDuration>6 hours</BookingOptionDuration>
              <BookingOptionPrice>{dollarsNoCentsWithSymbol(datePricingCurrentPackage.six_hours)}</BookingOptionPrice>
            </BookingOption>
          )}

          {!!datePricingCurrentPackage?.all_day && (
            <BookingOption>
              <BookingOptionDuration>8 hours</BookingOptionDuration>
              <BookingOptionPrice>{dollarsNoCentsWithSymbol(datePricingCurrentPackage.all_day)}</BookingOptionPrice>
            </BookingOption>
          )}
        </BookingOptionContent>
        <FuelWrapper data-testid="pdp-booking-options-fuel">
          <FuelIcon />
          <FuelText>Fuel {bookingOption.fuel_policy === 'renter_pays' ? 'is not' : 'is'} included.</FuelText>
          <a
            href="https://support.boatsetter.com/hc/en-us/articles/360034663992-Paying-for-fuel"
            target="_blank"
            rel="noreferrer"
            data-testid="pdp-booking-options-fuel-learn-more"
          >
            Learn more
          </a>
        </FuelWrapper>
      </BookingOptionsContainer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 60px;
  scroll-margin-top: 70px;

  ${mediaMMdMax} {
    padding-top: 35px;

    ${SubHeading} {
      margin-bottom: 22px;
    }
  }
`;

const FuelText = styled.span`
  font-weight: 600;
`;

const FuelWrapper = styled(Flex)`
  gap: 8px;
  padding-top: 30px;

  a {
    font-weight: 500;
  }

  ${mediaMMdMax} {
    padding: 24px 0 4px;
    font-size: 14px;
  }
`;

const BookingOptionsContainer = styled.div`
  max-width: 480px;
  padding-top: 45px;

  ${mediaMMdMax} {
    border: 1px solid #dbdfe5;
    border-radius: 9px;
    padding: 16px 24px;
  }
`;

const BookingHeader = styled(Flex)`
  gap: 40px;
  border-bottom: 1px solid #dbdfe5;
`;

const BookingOptionCategory = styled.div<{ active: boolean }>`
  font-weight: 600;
  cursor: pointer;
  color: #5e696a;

  ${({ active }) =>
    active &&
    css`
      color: #0751c2;
      padding-bottom: 12px;
      border-bottom: 3px solid #0751c2;
    `}

  ${mediaMMdMax} {
    flex: 1;
    display: flex;
    justify-content: center;
  }
`;
const BookingOptionContent = styled.div``;

const BookingOptionDuration = styled.div`
  font-weight: 500;
  min-width: 200px;

  ${mediaMMdMax} {
    font-weight: 400;
  }
`;

const BookingOptionPrice = styled.div`
  font-weight: 600;
`;

const BookingOption = styled(Flex)`
  padding: 13px 0;
  border-bottom: 1px solid rgba(219, 223, 229, 0.5);

  ${mediaMMdMax} {
    justify-content: space-between;
  }
`;
