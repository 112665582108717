import { useMemo } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useTrips } from 'components/pdp/v3/hooks';
import { Flex } from '../../primitives/flex';

const OutstandingOffer = ({ boatId, className = null }) => {
  const { myTrips } = useTrips(boatId, ['approved', 'pre_approved', 'unverified', 'payment_held', 'initialized']);
  const BannerInfo = useMemo(() => {
    const activeBooking = myTrips.find((trip) => ['approved', 'unverified'].includes(trip.state));
    const activeBookingRequest = myTrips.find((trip) => ['payment_held'].includes(trip.state));
    const preApprovedInquiry = myTrips.find((trip) => ['pre_approved'].includes(trip.state));
    const activeInquiry = myTrips.find((trip) => ['initialized'].includes(trip.state));

    if (activeBooking) {
      return <Banner id={activeBooking.id} text="an existing booking" />;
    }
    if (activeBookingRequest) {
      return <Banner id={activeBookingRequest.id} text="an existing booking request" />;
    }
    if (preApprovedInquiry) {
      return <Banner id={preApprovedInquiry.conversation_id} text="a pre-approved inquiry" isInquiry />;
    }
    if (activeInquiry) {
      return <Banner id={activeInquiry.conversation_id} text="an existing inquiry" isInquiry />;
    }
    return null;
  }, [myTrips]);

  return (
    BannerInfo && (
      <Wrapper className={className} gap="10px">
        <>
          <WarningIcon />
          {BannerInfo}
        </>
      </Wrapper>
    )
  );
};

const Banner = ({ id, text, isInquiry = false }) => (
  <div>
    {`You already have ${text} for this boat. `}
    {isInquiry ? (
      <Link href={`/a/inbox/${id}`}>View&nbsp;Conversation</Link>
    ) : (
      <Link href={`/bookings/${id}`}>See&nbsp;details</Link>
    )}
  </div>
);

const WarningIcon = () => (
  <svg width="20" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4177 12.3869L11.2194 1.2663C10.7729 0.475614 9.94124 -0.000488281 9.01325 -0.000488281C8.07651 -0.000488281 7.25358 0.475614 6.80709 1.2663L0.582554 12.3784C0.153577 13.1436 0.171087 14.0532 0.626327 14.8014C1.08157 15.5496 1.89575 16.0002 2.79747 16.0002H15.2115C16.1045 16.0002 16.9187 15.5496 17.3739 14.8014C17.8292 14.0617 17.8467 13.1606 17.4177 12.3869ZM16.2446 14.1553C16.0257 14.5124 15.6405 14.7249 15.2115 14.7249H2.78872C2.35974 14.7249 1.97454 14.5124 1.74692 14.1553C1.5193 13.7982 1.5193 13.3646 1.72065 12.999L7.94519 1.88692C8.16406 1.50434 8.55801 1.28332 9.0045 1.28332C9.45098 1.28332 9.84494 1.51284 10.0638 1.88692L16.2621 13.0075C16.4722 13.3646 16.4634 13.7982 16.2446 14.1553ZM8.33915 8.11037V4.99016C8.33915 4.64158 8.63681 4.35252 8.99575 4.35252C9.35468 4.35252 9.65234 4.64158 9.65234 4.99016V8.11037C9.65234 8.45895 9.35468 8.74802 8.99575 8.74802C8.63681 8.74802 8.33915 8.45895 8.33915 8.11037ZM10.2389 11.7492C10.2389 12.4124 9.67861 12.9565 8.99575 12.9565C8.31289 12.9565 7.75259 12.4124 7.75259 11.7492C7.75259 11.0861 8.31289 10.542 8.99575 10.542C9.67861 10.542 10.2389 11.0776 10.2389 11.7492Z"
      fill="#BC350A"
    />
  </svg>
);

const Wrapper = styled(Flex)`
  padding: 15px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  background: #fafdff;
  border: 1px solid rgba(189, 227, 253, 0.768684);
  border-radius: 9px;
  svg {
    padding-top: 5px;
  }
`;

export default OutstandingOffer;
