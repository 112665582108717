import Bugsnag from '@bugsnag/js';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { dehydrate, QueryClient } from 'react-query';
import getConfig from 'next/config';
import DefaultLayout from 'components/layouts/DefaultLayout';
import Reviews from 'components/pdp/v3/Reviews';
import CrewMembers from 'components/pdp/v3/CrewMembers';
import ThingsToKnow from 'components/pdp/v3/ThingsToKnow';
import { layoutMaxWidth, SubHeading } from 'components/pdp/v3/common';
import { Flex, FlexCenterBoth, FlexColMdMax } from 'components/primitives/flex';
import BoatGallery from 'components/pdp/v3/BoatGallery';
import BookingOptions from 'components/pdp/v3/BookingOptions';
import BookingWidget from 'components/forms/bookingWidget/BookingWidget';
import {
  useBoatDetails,
  useBottomBarVisible,
  useIncrementPageViewMutation,
  useInfiniteBoatReviews,
  useInfiniteCaptains,
} from 'components/pdp/v3/hooks';
import BottomBar from 'components/pdp/v3/BottomBar';
import Specifications from 'components/pdp/v3/Specifications';
import Features from 'components/pdp/v3/Features';
import SimilarBoats from 'components/pdp/v3/SimilarBoats';
import BoatHeader from 'components/pdp/v3/BoatHeader';
import { useBreakpoint } from 'components/BreakpointProvider';
import BoatLocation from 'components/pdp/v3/BoatLocation';
import { mediaMLgMin, mediaMSmMax } from 'helpers/breakpoints';
import { getDeletedBoatRedirectBody } from 'components/pdp/v3/utils';
import {
  bookingCaptainIdAtom,
  bookingCaptainPublicIdAtom,
  selectedBookingPackageAtom,
  tripStartDayjsAtom,
  tripFinishDayjsAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import useAbandonEffect from 'utils/tracking/useAbandonEffect';
import Footer from 'components/Footer';
import { useInitializeBookingFormData } from 'components/forms/bookingWidget/hooks';
import { BoatsApi } from 'swagger/apis/boats-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { Captain } from 'swagger/models';
import { getAsString } from 'helpers';
import { useRouter } from 'next/router';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { boatIdAtom, bookingIdAtom } from 'components/checkout/v2/jotaiStore';
import { useIsRenterInsuranceEnabled } from 'components/checkout/buoy/utils';
import dayjs from 'dayjs';
import getDefaultStartDate from '../../src/app/helpers/dateHelpers';
import Addons from '../../src/app/components/pdp/v3/Addons';

const { publicRuntimeConfig } = getConfig();

export default function Id({ id }) {
  const breakpoints = useBreakpoint();

  const router = useRouter();
  const boatParamId = getAsString(router.query.id);
  const boatId = id || boatParamId;

  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;
  const { bookingWidgetRef, bottomBarVisible } = useBottomBarVisible<HTMLDivElement>(smallScreen);

  const [bookingCaptainId, setBookingCaptainId] = useAtom(bookingCaptainIdAtom);
  const setBookingCaptainPublicId = useSetAtom(bookingCaptainPublicIdAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const setBoatId = useSetAtom(boatIdAtom);
  const setBookingId = useSetAtom(bookingIdAtom);
  const setTripStart = useSetAtom(tripStartDayjsAtom);
  const setTripFinishAtom = useSetAtom(tripFinishDayjsAtom);

  const { boatDetails = {}, isLoading } = useBoatDetails(boatId);
  const { pages } = useInfiniteCaptains(
    boatId,
    selectedBookingPackage?.package_type === 'captained',
    boatDetails.captain_network_enabled
  );

  // add to see if boat is eligible for renter insurance
  useIsRenterInsuranceEnabled();

  const { isLoading: areBoatReviewsLoading, reviewsTotal } = useInfiniteBoatReviews(boatId);

  const incrementPageViewMutation = useIncrementPageViewMutation();

  useEffect(() => {
    if (boatId) {
      setBoatId(boatId);
      incrementPageViewMutation.mutateAsync(boatId);
    }
  }, [boatId]);

  useEffect(() => {
    setBookingId('');

    // If start_period is not set or is in the past, set it to the default start date
    const isStartDatePast = dayjs().isAfter(dayjs(getAsString(router.query.start_period)));
    const tripDaysCount = dayjs(getAsString(router.query.end_period)).diff(
      dayjs(getAsString(router.query.start_period)),
      'days'
    );

    const routerStartPeriod =
      router.query.start_period && !isStartDatePast
        ? getAsString(router.query.start_period)
        : getDefaultStartDate({ timeZone: boatDetails.time_zone }).toISOString().split('T')[0];

    setTripStart(dayjs(routerStartPeriod));

    // Multi-day logic:
    if (router.query.end_period && isStartDatePast) {
      const defaultEndDate = dayjs(routerStartPeriod).add(tripDaysCount, 'days').toISOString().split('T')[0];
      setTripFinishAtom(dayjs(defaultEndDate));

      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          start_period: routerStartPeriod,
          end_period: defaultEndDate,
        },
      });
      return;
    }

    // Single-day logic:
    if (!router.query.start_period || isStartDatePast) {
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          start_period: routerStartPeriod,
        },
      });
    }
  }, []);

  useAbandonEffect({
    boat_id: boatId,
    abandon_type: 'pdp',
    trip_start: null,
    trip_finish: null,
    conversation_url: null,
    last_searched_plp_url: null,
    last_searched_pdp_url: true,
    abandoned_checkout_url: null,
    last_searched_location: null,
    last_searched_trip_time: null,
  });

  const flatCaptainsMap = useMemo<Captain[]>(
    () => pages.reduce((prev, current) => [...prev, ...current.data], []),
    [pages]
  );

  useEffect(() => {
    if (bookingCaptainId) return;
    setBookingCaptainId(flatCaptainsMap[0]?.id);
    setBookingCaptainPublicId(flatCaptainsMap[0]?.user?.id);
  }, [flatCaptainsMap]);

  const primaryPackage = boatDetails?.packages?.find((p) => p.id === boatDetails.cheapest_package.id);
  useInitializeBookingFormData(boatId, primaryPackage);

  const hasNoReviews = reviewsTotal === 0;
  const isBoatCaptained = selectedBookingPackage?.package_type === 'captained';

  if (isLoading) {
    return (
      <FlexCenterBoth height="100vh">
        <LoadingSpinner size="medium" color="#72D4BA" />
      </FlexCenterBoth>
    );
  }

  if (boatDetails?.state === 'deleted') {
    return (
      <DefaultLayout searchBar noFollow={false} isPdp path="pdpv3">
        <Container>
          <DeletedWrapper>
            <SubHeading as="h4">That ship has sailed</SubHeading>
            <div>
              Looks like the boat you are looking for is no longer active. Explore some similar boats in your area
            </div>
          </DeletedWrapper>
          <SimilarBoats boatId={boatId} />
        </Container>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout customFooter={<CustomFooter />} searchBar noFollow={false} isPdp path="pdpv3">
      <BoatGallery boatId={boatId} />

      <Container>
        <MainInfo gap="24px">
          <Left>
            <BoatHeader boatId={boatId} isBoatCaptained={isBoatCaptained} />
            <Features boatId={boatId} />
            <Specifications boatId={boatId} />
            <BookingOptions boatId={boatId} packages={boatDetails.packages} primaryPackage={primaryPackage} />
            <Addons boatId={boatId} />
          </Left>

          {!smallScreen && (
            <Right id="bookingWidget" data-testid="pdp-pre-checkout-widget">
              <BookingWidget boatId={boatId} primaryPackage={primaryPackage} ref={bookingWidgetRef} />
            </Right>
          )}
        </MainInfo>

        <Reviews
          boatId={boatId}
          boatRating={boatDetails.rating}
          isLoading={areBoatReviewsLoading}
          hasNoReviews={hasNoReviews}
        />

        <BoatLocation boatId={boatId} />

        <CrewMembers
          primaryPackage={primaryPackage}
          owner={boatDetails.primary_manager}
          captain={flatCaptainsMap.find((c) => c.id === bookingCaptainId)}
          isBoatCaptained={isBoatCaptained}
          boatId={boatId}
        />

        <ThingsToKnow
          boatId={boatId}
          boatRules={boatDetails.boat_rules}
          cancellationPolicy={boatDetails.cancellation_policy}
          captainNetworkEnabled={boatDetails.captain_network_enabled}
          isBoatCommercial={primaryPackage?.insurance_type === 'commercial'}
        />

        <SimilarBoats boatId={boatId} />
      </Container>
      <BottomBar
        boatId={boatId}
        primaryPackage={primaryPackage}
        hideReviews={hasNoReviews}
        isVisible={bottomBarVisible}
      />
    </DefaultLayout>
  );
}

const CustomFooter = styled(Footer)`
  ${mediaMSmMax} {
    padding-bottom: 100px;
  }
`;

const DeletedWrapper = styled.div`
  padding-top: 150px;
  h4 {
    font-size: 35px;
  }
`;

const Right = styled(Flex)``;

const Left = styled.div`
  ${mediaMLgMin} {
    width: clamp(400px, 100%, 800px);
  }
`;

const MainInfo = styled(FlexColMdMax)`
  justify-content: space-between;
  border-bottom: 1px solid #dbdfe5;
  padding-bottom: 60px;
  ${mediaMSmMax} {
    padding-bottom: 35px;
  }
`;

const Container = styled.div`
  ${layoutMaxWidth};
  padding: 26px;
`;

export const getServerSideProps = async ({ query }) => {
  const { id } = query;

  const boatApi = new BoatsApi({
    ...OpenApiConfiguration,
    basePath:
      publicRuntimeConfig.CYPRESS === 'true' ? 'http://127.0.0.1:3100' : publicRuntimeConfig.LOCAL_CLUSTER_MARKETPLACE,
  } as any);

  const queryClient = new QueryClient();

  try {
    await queryClient.fetchQuery(['boatDetail', id, query.package_type || null], () =>
      boatApi
        .domesticV2BoatsIdGet(id, {
          params: {
            package_type: query.package_type,
          },
        })
        .then((response) => response.data)
    );
  } catch (e) {
    Bugsnag.notify(e);
    // This assumes the error has a response and a status. This isn't always the
    // case.
    if (e.response?.status && e.response.status === 410) {
      return getDeletedBoatRedirectBody(e.response.data);
    }
    if (e.response?.status && e.response.status === 404) {
      return {
        notFound: true,
      };
    }
  }

  return {
    props: {
      id,
      dehydratedState: dehydrate(queryClient),
    },
  };
};
