import { useBreakpoint } from 'components/BreakpointProvider';
import { FlexCol, FlexRowMdMax } from 'components/primitives/flex';
import useModal from 'components/remodal/v2/useModal';
import Button from 'components/ui/Button/Button';
import dayjs from 'dayjs';
import { mediaMMdMax } from 'helpers/breakpoints';
import { Fragment } from 'react';
import styled from 'styled-components';
import { BoatReviews } from 'swagger/models';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import StarRating from './StarRating';
import { UserCardReviewer, UserCardReviewerSkeleton } from './UserCard';
import { ContainerGrid1to4, SubHeading } from './common';
import { useInfiniteBoatReviews } from './hooks';
import ReviewsModalContent from './modals/ReviewsModalContent';

const loadingUserCardSkeleton = <UserCardReviewerSkeleton />;

type Props = {
  isLoading: boolean;
  boatRating: number;
  hasNoReviews: boolean;
  boatId: string;
};

export default function Reviews({ isLoading, boatRating, hasNoReviews, boatId }: Props) {
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const { pages: reviews, reviewsTotal } = useInfiniteBoatReviews(boatId);

  const { modal, openModal } = useModal(<ReviewsModalContent boatId={boatId} boatRating={boatRating} />, {
    isOnRight: true,
    hideCloseButton: smallScreen,
    trackingName: 'reviews_modal_content',
  });

  if (!isLoading && hasNoReviews) {
    return null;
  }

  const renderReviewer = getRenderReviewer(openModal);

  const flatReviews = reviews.map(({ data }) => data).flat();

  const showButton = !isLoading && reviewsTotal > 6;

  return (
    <Container id="reviews" data-testid="component-reviews-and-rating-container">
      <LeftColumn>
        <HeadingContainer>
          <SubHeading>Ratings & reviews</SubHeading>
          <StarRating short countText="rating" reviewCount={isLoading ? 0 : reviewsTotal} rating={boatRating} />
        </HeadingContainer>
        {!smallScreen && showButton && <Button outline onClick={openModal} text={`SEE ALL ${reviewsTotal} REVIEWS`} />}
      </LeftColumn>

      {smallScreen ? (
        <SwiperContainer>
          <StyledSwiper spaceBetween={24} slidesPerView={reviews?.length === 1 ? 1 : 1.25}>
            {flatReviews.slice(0, 10)?.map((review) => (
              <SwiperSlide key={review.id}>{isLoading ? loadingUserCardSkeleton : renderReviewer(review)}</SwiperSlide>
            ))}
          </StyledSwiper>
        </SwiperContainer>
      ) : (
        <ReviewsWrapper data-testid="component-reviews-and-ratings-reviews">
          {isLoading
            ? [...Array(6)].map((_, i) => <Fragment key={i}>{loadingUserCardSkeleton}</Fragment>)
            : flatReviews.slice(0, 6).map(renderReviewer)}
        </ReviewsWrapper>
      )}
      {smallScreen && showButton && (
        <StyledButton outline onClick={openModal} text={`SEE ALL ${reviewsTotal} REVIEWS`} />
      )}

      {modal}
    </Container>
  );
}

const getRenderReviewer =
  (toggleSidebar) =>
  ({ id, author, text, created_at, rating }: BoatReviews) =>
    (
      <UserCardReviewer
        key={id}
        profilePhotoUrl={author.picture.url}
        text={text}
        reviewerName={author.first_name}
        reviewDate={dayjs(created_at).format('MMM YYYY')}
        rating={rating}
        onReadMoreClick={toggleSidebar}
      />
    );

const Container = styled(ContainerGrid1to4)`
  scroll-margin-top: 70px;
  width: 100%;
  max-width: 100%;

  button {
    width: fit-content;
    padding: 0 22px;
  }
`;

const StyledButton = styled(Button)`
  width: 100% !important;
`;

const ReviewsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 42px 96px;
`;

const LeftColumn = styled(FlexCol)`
  justify-content: space-between;
`;

const HeadingContainer = styled(FlexRowMdMax)`
  justify-content: space-between;
`;

const SwiperContainer = styled.div`
  ${mediaMMdMax} {
    overflow: hidden;
    margin: 0 -21px;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;

  ${mediaMMdMax} {
    padding: 0 21px !important;

    .swiper-slide {
      height: unset;
    }
  }
`;
