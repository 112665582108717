import styled, { css } from 'styled-components';
import { selectedBookingPackageAtom } from 'components/forms/bookingWidget/jotaiStore';
import { useAtomValue } from 'jotai';
import { useUserDetails } from 'auth/mutations/userHooks';
import Button from 'components/ui/Button/Button';
import { useBoatDetails } from '../../pdp/v3/hooks';
import { useBreakpoint } from '../../BreakpointProvider';

const BookingCTA = ({ loading, onClick = null, className = '', boatId, disabled = false }) => {
  const { boatDetails } = useBoatDetails(boatId);
  const { userId } = useUserDetails();

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;
  const viewerIsOwner = userId === boatDetails?.primary_manager?.id;
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);

  const getText = () => {
    if (viewerIsOwner) return 'You own this boat';

    if (boatDetails?.activated) {
      return selectedBookingPackage?.instant_bookable ? (
        <IBWrapper>
          <IBIcon />
          Instant book
        </IBWrapper>
      ) : (
        'Request to book'
      );
    }
    return smallScreen ? 'Boat unavailable' : 'Boat currently unavailable';
  };

  return (
    <Wrapper className={className}>
      <SButton
        ib={selectedBookingPackage?.instant_bookable}
        type="submit"
        disabled={!boatDetails?.activated || viewerIsOwner || loading || disabled}
        loading={loading}
        onClick={onClick}
        text={getText()}
      />
    </Wrapper>
  );
};

const SButton = styled(Button)<{ ib: boolean }>`
  padding: 0 25px !important;

  ${({ ib }) =>
    ib &&
    css`
      background: #0a4195 !important;
      &:hover {
        background: #083170 !important;
      }
    `}
`;

const IBWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
`;

const IBIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="#F4C064" />
    <path
      d="M8.85244 10.9147H4.63134C4.30722 10.9147 4.12566 10.5465 4.32591 10.2952L10.0228 3.14807C10.2782 2.82769 10.7971 3.06995 10.7074 3.46768L9.77666 7.59634H13.9535C14.2758 7.59634 14.4579 7.96088 14.2614 8.21267L9.08242 14.8493C8.84109 15.1586 8.34002 14.9555 8.38912 14.5684L8.85244 10.9147Z"
      fill="#001D58"
    />
  </svg>
);

const Wrapper = styled.div`
  display: flex;
  button {
    width: 100%;
  }
`;

export default BookingCTA;
