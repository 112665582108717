import styled from 'styled-components';
import { Flex, FlexAlignCenter, FlexCenterBoth, FlexCol, ItemFlex1 } from 'components/primitives/flex';
import Image from 'next/image';
import { cloudFlareLoader } from 'utils/images';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import { CheckIcon, EmailIcon, FacebookIcon, ShareLinkIcon, TwitterIcon } from '../icons';
import { SubHeading } from '../common';
import StarRating from '../StarRating';
import ShareMailForm from '../forms/ShareEmailForm';
import useRudderStackTrack from '../../../../hooks/useRudderStackTrack';

export default function ShareBoatModal({
  boatId,
  makeAndModel,
  boatLocation,
  boatUrl,
}: {
  boatId: string;
  makeAndModel: string;
  boatLocation: string;
  boatUrl: string;
}) {
  const rudderTrack = useRudderStackTrack();
  const { boatDetails } = useBoatDetails(boatId);
  const [isCopied, handleCopy] = useCopyToClipboard(4000);
  const [shareMailFormVisible, setShareMailFormVisible] = useState(false);

  const twitterShareText = `Check out this great boat on @boatsetter: ${makeAndModel} in ${boatLocation}! `;

  const copyLinkToClipboard = useCallback(() => {
    handleCopy(boatUrl);
    rudderTrack('selection_share_boat', { type: 'copy' });
  }, [boatUrl]);

  const trackShare = (type) => () => {
    rudderTrack('selection_share_boat', {
      type,
    });
  };

  const bestPhotoUrl = boatDetails?.best_photo?.thumbnail?.url;

  if (isEmpty(boatDetails)) {
    return null;
  }

  return (
    <>
      <SubHeading>Share this boat</SubHeading>

      <Flex gap="26px" margin="34px 0 52px">
        {bestPhotoUrl && (
          <StyledImage
            alt="Boat photo thumbnail"
            src={new URL(bestPhotoUrl)?.pathname.slice(1)}
            width={122}
            height={82}
            loader={cloudFlareLoader as any}
          />
        )}
        <FlexCol>
          <BoatName>{boatDetails.listing_tagline}</BoatName>
          <BoatLocation>{boatLocation}</BoatLocation>
          {boatDetails.review_count > 0 && (
            <StarRating rating={boatDetails.rating} reviewCount={boatDetails.review_count} />
          )}
        </FlexCol>
      </Flex>

      {shareMailFormVisible ? (
        <ShareMailForm makeAndModel={makeAndModel} boatLocation={boatLocation} boatUrl={boatUrl} />
      ) : (
        <Flex gap="20px">
          <ItemFlex1>
            <FlexCol gap="20px">
              <ShareButton
                as="a"
                onClick={trackShare('facebook')}
                target="_blank"
                href={`http://www.facebook.com/sharer.php?u=${boatUrl}`}
              >
                <FacebookIcon width={19} height={19} /> Facebook
              </ShareButton>
              <ShareButton
                as="a"
                target="_blank"
                onClick={trackShare('twitter')}
                href={`https://twitter.com/intent/tweet?source=tweetbutton&text=${twitterShareText}&url=${encodeURIComponent(
                  boatUrl
                )}`}
              >
                <TwitterIcon /> Twitter
              </ShareButton>
            </FlexCol>
          </ItemFlex1>
          <ItemFlex1>
            <FlexCol gap="20px">
              <ShareButton onClick={copyLinkToClipboard}>
                <ShareLinkIcon />
                {isCopied ? (
                  <FlexAlignCenter as="b" gap="8px">
                    Copied! <StyledCheckIcon />
                  </FlexAlignCenter>
                ) : (
                  'Copy link'
                )}
              </ShareButton>
              <ShareButton
                onClick={() => {
                  setShareMailFormVisible(true);
                  rudderTrack('selection_share_boat', { type: 'email' });
                }}
              >
                <EmailIcon /> E-mail
              </ShareButton>
            </FlexCol>
          </ItemFlex1>
        </Flex>
      )}
    </>
  );
}

const ShareButton = styled(FlexCenterBoth)`
  border-radius: 8px;
  border: 1px solid #cdd1d2;
  color: #17233c !important;
  height: 62px;
  gap: 8px;
  cursor: pointer;
`;

const BoatName = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
`;

const BoatLocation = styled.div`
  margin: 4px 0 10px;
  font-weight: 500;
  line-height: 22px;
`;

const StyledImage = styled(Image)`
  border-radius: 5px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: #2a8500;
  }
`;
