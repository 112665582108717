import { useAtomValue } from 'jotai';
import { isBookingMultiDayAtom, selectedBookingPackageAtom } from 'components/forms/bookingWidget/jotaiStore';
import SelectInput from '../TimePicker/SelectInput';
import { createDurationOption } from './utils';

const DurationPicker = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  onChange = (e) => {},
  defaultValue,
  setter,
  error = null,
  className = null,
  modalVersion = false,
}) => {
  const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);

  const getOptions = () => {
    if (!selectedBookingPackage) return [];

    const packages = [];

    if (selectedBookingPackage.two_hour_price_cents > 0) packages.push(createDurationOption(2, 'two_hour_price_cents'));
    if (selectedBookingPackage.three_hour_price_cents > 0)
      packages.push(createDurationOption(3, 'three_hour_price_cents'));
    if (selectedBookingPackage.half_day_cents > 0) packages.push(createDurationOption(4, 'half_day_cents'));
    if (selectedBookingPackage.six_hour_price_cents > 0) packages.push(createDurationOption(6, 'six_hour_price_cents'));
    if (selectedBookingPackage.all_day_cents > 0) packages.push(createDurationOption(8, 'all_day_cents'));

    return packages;
  };

  return (
    <div data-testid="pdp-booking-widget-duration-picker-container">
      <SelectInput
        modalVersion={modalVersion}
        setter={setter}
        name="DurationPicker"
        error={error}
        options={getOptions()}
        icon={<DurationIcon />}
        className={className}
        defaultValue={defaultValue}
        onChange={onChange}
        title="Duration"
        height="auto"
        isDisabled={isBookingMultiDay}
      />
    </div>
  );
};

export const DurationIcon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="10.5" r="6.9" stroke="#5E696A" strokeWidth="1.2" />
    <line x1="7.5" y1="4" x2="7.5" y2="2" stroke="#5E696A" />
    <line x1="7.5" y1="10.5" x2="7.5" y2="6.5" stroke="#5E696A" strokeLinecap="round" />
    <line x1="13.8828" y1="6.79892" x2="14.7488" y2="6.29892" stroke="#5E696A" />
    <line x1="6.6" y1="1.4" x2="8.4" y2="1.4" stroke="#5E696A" strokeWidth="1.2" strokeLinecap="round" />
    <line
      x1="14.8196"
      y1="5.21962"
      x2="15.7196"
      y2="6.77846"
      stroke="#5E696A"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle cx="7.5" cy="11" r="1" fill="#5E696A" />
  </svg>
);

export default DurationPicker;
