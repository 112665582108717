import { Flex } from 'components/primitives/flex';
import { Text } from 'components/primitives/typography';
import { mediaMMdMax } from 'helpers/breakpoints';
import { dollarsNoCentsWithSymbol } from 'helpers/currency';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SubHeading, ToggleLink } from './common';
import { useBoatDetails } from './hooks';

type Props = {
  boatId: string;
};

export default function Addons({ boatId }: Props) {
  const { boatDetails } = useBoatDetails(boatId);

  const [moreAddons, setMoreAddons] = useState(false);
  if (boatDetails.boat_add_ons?.length === 0) return null;

  const MAX_ADDONS = 5;

  return (
    <Container id="bookingOptions">
      <SubHeading>Add-ons</SubHeading>
      <Text>Explore optional add-ons to tailor your booking at checkout.</Text>
      <BookingOptionsContainer>
        <BookingOptionContent>
          {boatDetails.boat_add_ons?.slice(0, moreAddons ? 100 : MAX_ADDONS).map(({ add_on, ...addonDetails }) => (
            <BookingOption key={add_on.id}>
              <BookingOptionDuration>{add_on.name}</BookingOptionDuration>
              <BookingOptionPrice gap="5px">
                <Text>{dollarsNoCentsWithSymbol(addonDetails.price_cents)}</Text>
                <AddonPer fWeight={400} fSize="12px">
                  {' '}
                  <Slash>/</Slash> {addonDetails.display_short_price_type}
                </AddonPer>
              </BookingOptionPrice>
            </BookingOption>
          ))}
        </BookingOptionContent>
      </BookingOptionsContainer>
      {boatDetails.boat_add_ons?.length > MAX_ADDONS && (
        <ToggleLink onClick={() => setMoreAddons(!moreAddons)}>See {moreAddons ? 'less' : 'all'}</ToggleLink>
      )}
    </Container>
  );
}

const AddonPer = styled(Text)`
  align-self: center;
`;

const Slash = styled.span`
  ${mediaMMdMax} {
    display: none;
  }
`;

const Container = styled.div`
  padding-top: 60px;
  scroll-margin-top: 70px;

  ${mediaMMdMax} {
    padding-top: 35px;

    ${SubHeading} {
      margin-bottom: 22px;
    }
  }
`;

const BookingOptionsContainer = styled.div`
  max-width: 480px;
  padding-top: 25px;

  ${mediaMMdMax} {
    border-radius: 9px;
  }
`;

const BookingOptionContent = styled.div``;

const BookingOptionDuration = styled.div`
  font-weight: 400;
  min-width: 250px;
`;

const BookingOptionPrice = styled(Flex)`
  font-weight: 600;
  ${mediaMMdMax} {
    gap: 0;
    align-items: flex-end;
    flex-flow: column;
  }
`;

const BookingOption = styled(Flex)`
  padding: 13px 0;
  :not(:last-child) {
    border-bottom: 1px solid rgba(219, 223, 229, 0.5);
  }

  ${mediaMMdMax} {
    align-items: center;
    justify-content: space-between;
  }
`;
