import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { FlexAlignCenter, FlexCol } from 'components/primitives/flex';
import { useTripCalculator } from 'hooks/queries';

export default function CancellationInfo({ isModal = false }) {
  const { tripCalculation } = useTripCalculator();

  return (
    <FreeCancellation gap="15px" isModal={isModal}>
      <ThumbsUpIcon />
      <FlexCol>
        <CancellationHeader>Free cancellation</CancellationHeader>
        <CancellationSubtext>
          Full refund before {dayjs(tripCalculation?.free_cancelation_until).format('MMM D, YYYY')}
        </CancellationSubtext>
      </FlexCol>
    </FreeCancellation>
  );
}

const CancellationHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
`;
const CancellationSubtext = styled.div`
  font-size: 12px;
  color: #5e696a;
`;

const FreeCancellation = styled(FlexAlignCenter)<{ isModal: boolean }>`
  margin-top: 20px;

  ${({ isModal }) =>
    isModal &&
    css`
      margin-top: 0;
    `}
`;

const ThumbsUpIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8057 7.83405C18.1273 7.16783 17.2227 6.72369 16.3181 6.72369H11.7953V3.0595C11.7953 1.39396 10.3253 0.0615234 8.62925 0.0615234C8.29004 0.0615234 7.95082 0.283596 7.83775 0.72774L5.80245 8.16716H1.27958C0.714216 8.16716 0.375 8.50027 0.375 8.94441V17.05C0.375 17.4942 0.714216 17.9383 1.27958 17.9383H6.48089H15.6397C17.2227 17.9383 18.6927 16.828 18.9188 15.1624L19.5972 10.3879C19.7103 9.49959 19.4842 8.50027 18.8057 7.83405ZM2.07108 9.8327H5.57631V16.2728H2.07108V9.8327ZM17.9012 10.1658L17.1097 14.9404C16.9966 15.7176 16.3181 16.2728 15.5266 16.2728H7.27239V9.05545L9.30769 1.8381C9.75997 2.06017 10.0992 2.50432 10.0992 3.0595V7.50094C10.0992 7.94508 10.4384 8.38923 11.0038 8.38923H16.4312C16.8835 8.38923 17.3358 8.6113 17.675 8.94441C17.7881 9.27752 17.9012 9.72166 17.9012 10.1658Z"
      fill="#0A4195"
    />
  </svg>
);
