import { Fragment, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexColMdMax, ItemFlex1 } from 'components/primitives/flex';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import { sectionStyles, SubHeading, ToggleLink } from './common';
import { useIsTextClamped } from './hooks';
import { UserCardOwner } from './UserCard';
import { mediaMMdMax } from '../../../helpers/breakpoints';

type Props = {
  description: string;
  // TODO complete and bind BoatPrimaryManager from swagger
  owner: any;
  showTopOwnerBadge: boolean;
  showNewOwnerBadge: boolean;
  boatId: string;
};

export default function BoatInfo({ description, owner, showTopOwnerBadge, showNewOwnerBadge, boatId }: Props) {
  const rudderTrack = useRudderStackTrack();
  const [readMore, setReadMore] = useState(false);
  const [isTextClamped, textRef] = useIsTextClamped<HTMLDivElement>(description, true);

  const toggleReadMore = useCallback(() => {
    setReadMore((isReadMore) => {
      rudderTrack('pdp_description_toggle_read', { boatId, open: !isReadMore });

      return !isReadMore;
    });
  }, [boatId, rudderTrack]);

  return (
    <Container id="boatInfo">
      <ItemFlex1>
        <SubHeading>The boat</SubHeading>
        {/* @ts-ignore */}
        {description && (
          <BoatDescription
            data-testid="pdp-boat-header-boat-info-description"
            onClick={toggleReadMore}
            trim={isTextClamped && !readMore}
            // @ts-ignore
            ref={textRef}
          >
            {description.split('\n').map((paragraph, i) => (
              <Fragment key={i}>
                {paragraph} <br />
                <br />
              </Fragment>
            ))}
            <Gradient />
          </BoatDescription>
        )}
        <div data-testid="pdp-boat-header-boat-info-description-read-more">
          {isTextClamped && (
            <ToggleLink data-testid="pdp-boat-header-boat-info-description-read-more-link" onClick={toggleReadMore}>
              Read {readMore ? 'less' : 'more'}
            </ToggleLink>
          )}
        </div>
      </ItemFlex1>

      <UserCardOwner
        ownerName={owner?.first_name}
        rating={owner?.rating}
        reviewCount={owner?.review_count}
        profilePhotoUrl={owner?.picture.url}
        isTopOwner={showTopOwnerBadge}
        isNewOwner={showNewOwnerBadge}
      />
    </Container>
  );
}

const Container = styled(FlexColMdMax)`
  ${sectionStyles};
  gap: 25px;
  scroll-margin-top: 70px;

  ${SubHeading} {
    ${mediaMMdMax} {
      padding-bottom: 20px;
    }
  }
`;

const BoatDescription = styled.div<{ trim: boolean }>`
  position: relative;
  color: #5e696a;
  line-height: 24px;
  font-weight: 400;
  p {
    margin-bottom: 15px;
  }

  ${({ trim }) =>
    trim &&
    css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      line-clamp: 7;
      box-orient: vertical;
      -webkit-box-orient: vertical;

      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 104.9%);

      ${Gradient} {
        display: block;
      }
    `}
`;

const Gradient = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  top: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 104.9%);
`;
