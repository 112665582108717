import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useBoatDetails } from 'components/pdp/v3/hooks';
import { useAtom, useAtomValue } from 'jotai';
import { bookingBoatIdAtom, bookingPassengersAtom } from 'components/forms/bookingWidget/jotaiStore';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';

const PassengerCounter = () => {
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const { boatDetails: bookingBoatDetails } = useBoatDetails(bookingBoatId);
  const [passengersCount, setPassengersCount] = useAtom(bookingPassengersAtom);

  const router = useRouter();
  const rudderTrack = useRudderStackTrack();

  const pushQuery = (newCount: number) => {
    router.push(
      {
        query: {
          ...router.query,
          passengers: newCount,
        },
      },
      null,
      { shallow: true, scroll: false }
    );
  };

  const increment = (e) => {
    e.preventDefault();
    if (passengersCount !== bookingBoatDetails?.capacity) {
      const passengers = passengersCount + 1;
      setPassengersCount(passengers);
      pushQuery(passengers);
      rudderTrack('selection_passengers', {
        action: 'increment',
        value: passengers,
      });
    }
  };

  const decrement = (e) => {
    e.preventDefault();
    if (passengersCount !== 1) {
      const passengers = passengersCount - 1;
      setPassengersCount(passengers);
      pushQuery(passengers);
      rudderTrack('selection_passengers', {
        action: 'decrement',
        value: passengers,
      });
    }
  };

  return (
    <Wrapper>
      <Symbol
        onClick={decrement}
        disabled={passengersCount === 1}
        data-testid="pdp-booking-widget-passenger-count-minus"
      >
        <Minus />
      </Symbol>
      <Display>
        <div data-testid="pdp-booking-widget-passenger-count-value">{passengersCount}</div>
        <div>passengers</div>
      </Display>
      <Symbol
        onClick={increment}
        disabled={passengersCount === bookingBoatDetails?.capacity}
        data-testid="pdp-booking-widget-passenger-count-plus"
      >
        <Plus />
      </Symbol>
    </Wrapper>
  );
};

const Minus = () => (
  <svg width="22" height="2" viewBox="0 0 22 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44219 1.11359H20.7171" stroke="#8E9697" strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);

const Plus = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.57823 9.63641H20.2691" stroke="#8E9697" strokeWidth="1.5" strokeLinecap="square" />
    <path d="M10.922 19.0024V1.37158" stroke="#8E9697" strokeWidth="1.5" strokeLinecap="square" />
  </svg>
);
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Symbol = styled.button`
  border: 1px solid #cdd1d2;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 8px;
  width: 85px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
      border: 1px solid #cdd1d2;
      opacity: 0.4;
    `}
`;

const Display = styled.div`
  font-weight: 500;
  user-select: none;
  font-size: 14px;

  text-align: center;
  justify-content: end;
  flex-flow: row;
  display: flex;
  gap: 6px;
  align-items: center;
`;

export default PassengerCounter;
