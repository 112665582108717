import { useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Navigation, Pagination, Virtual } from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { cloudFlareLoader } from 'utils/images';
import { useBreakpoint } from 'components/BreakpointProvider';
import { mediaMLgMax, mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import { useRouter } from 'next/router';
import { boatMakeAndModel } from 'helpers';
import { useGetBoatPhotosQuery } from 'components/blw/hooks';
import useModal from 'components/remodal/v2/useModal';
import getConfig from 'next/config';
import { useBoatDetails, useFavoriteBoat } from './hooks';
import NextImageWithLoading from './NextImageWithLoading';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';
import GalleryDetail from './GalleryDetail';
import { Flex, FlexCenterBoth } from '../../primitives/flex';
import { ArrowNextIcon } from './icons';
import ShareBoatModal from './modals/ShareBoatModal';

const paramsObj = { utm_source: 'boatsetter', utm_campaign: 'sharePDP', utm_medium: 'product' };
const searchParams = new URLSearchParams(paramsObj);
const { RAILS_URL } = getConfig().publicRuntimeConfig;

export default function BoatGallery({ boatId }: { boatId: string }) {
  const rudderTrack = useRudderStackTrack();
  const router = useRouter();
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { isBoatFavorite, addToFavorites } = useFavoriteBoat(boatId);

  const { boatPhotos = [], isLoading } = useGetBoatPhotosQuery(boatId);
  const { boatDetails } = useBoatDetails(boatId);
  const photos = isLoading ? boatDetails.photos : boatPhotos;
  const makeAndModel = boatMakeAndModel(boatDetails);
  const boatLocation = `${boatDetails.location?.city}, ${boatDetails.location?.state}`;
  const boatUrl = `${RAILS_URL}/boats/${boatId}?${searchParams.toString()}`;

  const { modal, openModal } = useModal(
    <ShareBoatModal boatId={boatId} makeAndModel={makeAndModel} boatLocation={boatLocation} boatUrl={boatUrl} />,
    {
      size: 'small',
      trackingName: 'share_boat_modal',
    }
  );

  const slidesPerScreen = useMemo(() => {
    if (boatPhotos?.length === 1) return 1;

    if (breakpoints.xs || breakpoints.sm || breakpoints.md) return 1;
    if (breakpoints.lg || breakpoints.xl || breakpoints.xxl) return 1.2;
    if (breakpoints.xxxl) return 1.65;
    return 2.2;
  }, [photos, breakpoints]);

  const getSizesTag = () => {
    if (boatPhotos?.length === 1) return `(min-width: 961px) calc(100vw - 30px), 100vw`;

    return `(min-width: 2560px) calc(100vw / 2.2 - 15px - 15px - 15px), (min-width: 1440px) calc(100vw * 0.65 - 15px - 15px), (min-width: 961px) calc(100vw * 0.8 - 15px - 15px), 100vw`;
  };

  const openShareBoatModal = useCallback(() => {
    rudderTrack('open_share_boat_modal');

    if (smallScreen && window.navigator && window.navigator.share) {
      window.navigator.share({
        title: boatDetails.listing_tagline,
        text: `Check out this great boat on Boatsetter: ${makeAndModel} in ${boatLocation}`,
        url: boatUrl,
      });
    } else {
      openModal();
    }
  }, [boatId, smallScreen]);

  const onChange = (swiper) => {
    rudderTrack('scroll_pdp_photo_gallery', {
      index: swiper.activeIndex,
      boat_id: boatId,
    });
  };

  const [activeSlideIndex, setActiveSlideIndex] = useState(-1);
  const [carouselInitialized, setCarouselInitialized] = useState(false);

  const handleImageClick = (index) => {
    setActiveSlideIndex(index);
    rudderTrack('open_pdp_photo_gallery', { boatId, photoIndex: index, device: smallScreen ? 'mobile' : 'desktop' });
  };

  return (
    <Container onePhoto={photos?.length === 1}>
      {activeSlideIndex !== -1 && (
        <GalleryDetail boatId={boatId} slide={activeSlideIndex} close={() => setActiveSlideIndex(-1)} photos={photos} />
      )}
      {(photos || [])?.length > 0 && !carouselInitialized && (
        <InvisibleWrapper>
          <CarouselImage>
            <NextImageWithLoading
              alt="boat"
              src={new URL(photos[0].standard.url)?.pathname.slice(1)}
              loader={cloudFlareLoader as any}
              sizes={getSizesTag()}
              fill
              priority
              style={{
                objectFit: 'cover',
              }}
            />
          </CarouselImage>
        </InvisibleWrapper>
      )}

      <Swiper
        className="mainGallery"
        onAfterInit={() => setCarouselInitialized(true)}
        spaceBetween={smallScreen ? 0 : 15}
        slidesPerView={slidesPerScreen}
        pagination={{ type: 'fraction' }}
        modules={[Pagination, Navigation, Virtual]}
        virtual
        onSlideChange={onChange}
        navigation={{
          prevEl: navigationPrevRef.current!,
          nextEl: navigationNextRef.current!,
        }}
      >
        {(photos || [])?.map((photo, i) => (
          <SwiperSlide key={photo.standard.url}>
            <CarouselImage
              onClick={() => handleImageClick(i)}
              className={`${i === (photos?.length || 0) - 1 && 'isLast'}`}
              data-testid="pdp-boat-gallery-photo"
            >
              <NextImageWithLoading
                key={photo}
                src={new URL(photo.standard.url)?.pathname.slice(1)}
                loader={cloudFlareLoader as any}
                fill
                sizes={getSizesTag()}
                style={{
                  objectFit: 'cover',
                }}
              />
            </CarouselImage>
          </SwiperSlide>
        ))}

        {(photos || [])?.length !== 1 && (
          <StyledArrowPrevIcon ref={navigationPrevRef} data-testid="pdp-boat-gallery-photo-swiper-prev" />
        )}
        {(photos || [])?.length !== 1 && (
          <StyledArrowNextIcon ref={navigationNextRef} data-testid="pdp-boat-gallery-photo-swiper-next" />
        )}

        {/* @ts-ignore */}
        <MobileBackButton onClick={() => router.back()} />

        <IconWrapper gap="20px" gapMdMax="16px">
          <Icon onClick={addToFavorites} data-testid="pdp-boat-gallery-favorite-icon">
            <HeartIcon isFavorite={isBoatFavorite} />
          </Icon>
          <Icon onClick={openShareBoatModal} data-testid="pdp-boat-gallery-share-icon">
            {smallScreen ? <ShareIconMobile /> : <ShareIconDesktop />}
          </Icon>
        </IconWrapper>
      </Swiper>

      {modal}
    </Container>
  );
}

const CarouselImage = styled.div`
  height: 100%;
  position: relative;

  img {
    border-radius: 16px;

    ${mediaMMdMax} {
      border-radius: 0;
    }
  }
`;

const InvisibleWrapper = styled.div`
  display: none;
  img {
    width: 1px;
    height: 1px;
  }
  ${mediaMMdMax} {
    display: block;
    width: 100vw;
    height: calc((100vw / 16) * 9);
    position: absolute;
    top: 0;
    max-height: 75vh;
    z-index: 1000;
    aspect-ratio: 4 / 2.6;
  }
`;

const Container = styled.div<{ onePhoto: boolean }>`
  padding-left: 15px;
  position: relative;
  margin-top: 5px;
  width: 100%;
  min-height: 560px;

  ${mediaMMdMax} {
    margin-top: 0;
    min-height: 220px;
  }

  .galleryDetail {
    .swiper-slide {
      width: 300px;
      height: clamp(300px, 80vh, 700px);
    }
  }

  .mainGallery {
    .swiper-slide {
      ${CarouselImage}.islast {
        ${mediaMLgMin} {
          margin-right: 15px !important;
          ${({ onePhoto }) =>
            onePhoto &&
            css`
              margin-right: 0 !important;
            `}
        }
      }
      cursor: pointer;
      aspect-ratio: 4 / 2.6;
      max-height: 75vh;

      ${mediaMLgMax} {
        height: calc((100vw / 16) * 9);
      }
    }
  }

  ${({ onePhoto }) =>
    onePhoto &&
    css`
      margin: 0 auto;
      max-width: calc(1292px + 26px + 26px);
      padding-right: 15px;
      .mainGallery {
        .swiper-slide {
          aspect-ratio: 4 / 3;
          max-height: 67vh;
        }
      }
    `}

  .mainGallery {
    .swiper-pagination-fraction {
      background: #17233ca1;
      border-radius: 34px;

      color: #fff;
      font-size: 12px;
      font-weight: 600;
      width: unset;
      left: 20px;
      bottom: 20px;
      padding: 4px 14px;
    }
  }

  ${mediaMMdMax} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledArrowNextIcon = styled(ArrowNextIcon)`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 8px;
  left: unset;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.3 ease-in-out;
  width: 47px;
  height: 47px;

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mediaMMdMax} {
    display: none;
  }
`;

export const StyledArrowPrevIcon = styled(StyledArrowNextIcon)`
  left: 8px;
  transform: translateY(-50%) rotate(180deg);
`;

const IconWrapper = styled(Flex)`
  position: absolute;
  z-index: 20;
  right: 20px;
  top: 20px;
`;

const Icon = styled(FlexCenterBoth)`
  background: white;
  cursor: pointer;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: scale(1.1);
  }

  ${mediaMMdMax} {
    height: 32px;
    width: 32px;
  }
`;

const MobileBackButton = styled(StyledArrowPrevIcon)`
  display: block;
  position: absolute;
  z-index: 20;
  left: 20px;
  top: 20px;
  transform: rotate(180deg);

  height: 32px;
  width: 32px;

  path {
    fill: #5e696a;
  }

  ${mediaMLgMin} {
    display: none;
  }
`;

const ShareIconDesktop = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2365 5.33405V13.0151C13.2365 13.3686 12.9497 13.6552 12.596 13.6552H1.49402C1.1403 13.6552 0.853516 13.3686 0.853516 13.0151V5.33405C0.853516 4.98051 1.1403 4.69397 1.49402 4.69397H3.62902C3.98273 4.69397 4.26952 4.98051 4.26952 5.33405C4.26952 5.68754 3.98273 5.97414 3.62902 5.97414H2.13452V12.375H11.9555V5.97414H10.888C10.5343 5.97414 10.2475 5.68754 10.2475 5.33405C10.2475 4.98051 10.5343 4.69397 10.888 4.69397H12.596C12.9497 4.69397 13.2365 4.98051 13.2365 5.33405ZM5.31012 2.8428L6.40452 1.96784V9.17058C6.40452 9.52407 6.6913 9.81067 7.04501 9.81067C7.39873 9.81067 7.68551 9.52407 7.68551 9.17058V1.96784L8.77991 2.8428C9.05612 3.06367 9.45919 3.01887 9.68014 2.74284C9.90115 2.46682 9.85638 2.064 9.58012 1.84314L7.44512 0.136242C7.41186 0.109676 7.37329 0.0954555 7.33665 0.0764426C7.31705 0.0662329 7.30016 0.0531582 7.27947 0.0449279C7.1281 -0.014976 6.96193 -0.014976 6.81056 0.0449279C6.78987 0.0531582 6.77298 0.0662329 6.75338 0.0764426C6.71674 0.0954555 6.67817 0.109676 6.64491 0.136242L4.50991 1.84314C4.23365 2.064 4.18888 2.46682 4.40989 2.74284C4.63084 3.01887 5.03391 3.06367 5.31012 2.8428Z"
      fill="#5E696A"
    />
  </svg>
);

const ShareIconMobile = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17881 6.77429L7.19104 2.76206V11.5955C7.19104 12.0423 7.55324 12.4045 8.00003 12.4045C8.44682 12.4045 8.80902 12.0423 8.80902 11.5955V2.76206L12.8212 6.77429C13.1372 7.09022 13.6494 7.09022 13.9653 6.77429C14.2813 6.45836 14.2813 5.94614 13.9653 5.63021L8.57207 0.236947C8.25614 -0.0789825 7.74392 -0.0789825 7.42799 0.236947L2.03473 5.63021C1.7188 5.94614 1.7188 6.45836 2.03473 6.77429C2.35066 7.09022 2.86288 7.09022 3.17881 6.77429ZM0 16.0901C0 16.5369 0.362197 16.8991 0.808989 16.8991H15.191C15.6378 16.8991 16 16.5369 16 16.0901C16 15.6433 15.6378 15.2812 15.191 15.2812H0.808989C0.362197 15.2812 0 15.6433 0 16.0901Z"
      fill="#5E696A"
    />
  </svg>
);

const HeartIcon = ({ isFavorite }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.0784 14.9047C10.0081 14.2874 16.6091 9.76291 16.6091 5.73371C16.6091 2.05481 13.6103 1.65465 13.1468 1.66279C13.005 1.65465 12.8599 1.66318 12.7155 1.67958C12.1636 1.74321 11.6064 1.93083 11.0585 2.23719C10.3991 2.60586 9.75887 3.13198 9.05136 3.8877C9.0085 3.93362 8.93267 3.93362 8.88981 3.8877C8.1823 3.13198 7.54139 2.60586 6.88201 2.23719C6.33407 1.93083 5.77689 1.74321 5.22499 1.67958C5.08059 1.66318 4.93619 1.65465 4.79376 1.66279C4.33088 1.65465 1.33203 2.05481 1.33203 5.73371C1.33203 9.76291 7.9324 14.2874 8.86212 14.9047C8.93004 14.95 9.01114 14.95 9.0784 14.9047Z"
      stroke={isFavorite ? '#72d4ba' : '#5E696A'}
      fill={isFavorite ? '#72d4ba' : undefined}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
