import { BoatCaptainOptionEnum } from 'swagger/models';

export const captainStatusMap = {
  [BoatCaptainOptionEnum.captain_optional]: {
    title: 'Captain optional',
    description: 'This boat is rented with or without a captain',
  },
  [BoatCaptainOptionEnum.captain_only]: {
    title: 'Captained',
    description: 'This boat is rented with a captain',
  },
  [BoatCaptainOptionEnum.no_captain_only]: {
    title: 'No captain',
    description: 'The renter can operate the boat',
  },
};
