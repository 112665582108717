import dayjs from 'dayjs';
import styled from 'styled-components';
import { FlexCenterBoth, FlexCol, FlexJustifySpaceBetween } from 'components/primitives/flex';
import { useCloseModal } from 'components/remodal/v2/hooks';
import { SubHeading } from '../common';
import StarRating from '../StarRating';
import { UserCardReviewer } from '../UserCard';
import { useBreakpoint } from '../../../BreakpointProvider';
import { mediaMLgMin, mediaMMdMax } from '../../../../helpers/breakpoints';
import { useInfiniteBoatReviews } from '../hooks';
import { InfiniteLoader, StyledInfiniteScroll } from './ChangeCaptainModal';

export default function ReviewsModalContent({ boatRating = 0, boatId }: { boatRating?: number; boatId: string }) {
  const closeModal = useCloseModal();
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const { pages, reviewsTotal, fetchNextPage, hasNextPage } = useInfiniteBoatReviews(boatId);
  const flatReviews = pages.map(({ data }) => data).flat();

  return (
    <FlexCol gap="40px" gapMdMax="24px">
      {smallScreen ? (
        <StickyHeader>
          <CloseWrapper onClick={closeModal}>
            <Close />
          </CloseWrapper>
          <SubHeading>Reviews</SubHeading>
          <StarRating short countText="review" reviewCount={reviewsTotal} rating={boatRating} />
        </StickyHeader>
      ) : (
        <div>
          <SubHeading>Ratings & reviews</SubHeading>
          <StarRating short countText="review" reviewCount={reviewsTotal} rating={boatRating} />
        </div>
      )}

      <Wrapper>
        <StyledInfiniteScroll
          dataLength={pages.length}
          next={fetchNextPage}
          height={window.innerHeight - 220}
          loader={<InfiniteLoader />}
          hasMore={hasNextPage}
        >
          {flatReviews.map((review) => (
            <StyledUserCardReviewer
              key={review.id}
              profilePhotoUrl={review.author.picture.url}
              text={review.text}
              reviewerName={review.author.first_name}
              reviewDate={dayjs(review.created_at).format('MMM YYYY')}
              rating={review.rating}
              clampText={false}
              isInModal
            />
          ))}
        </StyledInfiniteScroll>
      </Wrapper>
    </FlexCol>
  );
}

const Wrapper = styled.div`
  ${mediaMMdMax} {
    padding-top: 70px;
  }
`;

const StickyHeader = styled(FlexJustifySpaceBetween)`
  width: 100%;
  background: white;
  left: 0;
  padding: 20px;
  z-index: 100;
  position: absolute;
  align-items: center;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.11);
  top: 0;
`;

const CloseWrapper = styled(FlexCenterBoth)`
  background: #f5f7f9;
  cursor: pointer;
  width: 32px;
  border-radius: 50%;
  height: 32px;
`;

const Close = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="1.41406" width="2" height="14" rx="1" transform="rotate(-45 0 1.41406)" fill="#5E696A" />
    <rect
      x="1.41406"
      y="11.3135"
      width="2"
      height="14"
      rx="1"
      transform="rotate(-135 1.41406 11.3135)"
      fill="#5E696A"
    />
  </svg>
);

const StyledUserCardReviewer = styled(UserCardReviewer)`
  max-width: 440px;
  ${mediaMLgMin} {
    padding-bottom: 40px;
  }
`;
