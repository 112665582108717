import { useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { mediaMMdMax } from 'helpers/breakpoints';
import { SubHeading } from './common';
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from '../../../stacks/boat/constants';
import { useBoatDetails } from './hooks';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

const BoatMap = dynamic<any>(() => import('components/ui/BoatMap/BoatMap').then((m) => m));

export default function BoatLocation({ boatId }: { boatId: string }) {
  const { boatDetails } = useBoatDetails(boatId);

  const [mapEntry, mapWrapperRef] = useIntersectionObserver<HTMLDivElement>({
    rootMargin: '-150px 100px 0px 0px',
    threshold: 0.3,
  });
  const [hideMap, setHideMap] = useState(true);

  useEffect(() => {
    if (mapEntry?.isIntersecting) setHideMap(false);
  }, [mapEntry]);

  const mapCenter = {
    lat: Number(boatDetails.location?.lat) || DEFAULT_MAP_LAT,
    lng: Number(boatDetails.location?.lng) || DEFAULT_MAP_LNG,
  };

  return (
    <StyledBoatLocation id="boatLocation" data-testid="pdp-boat-location">
      <SubHeading>Boat location</SubHeading>
      <MapDescription>Exact location information is provided after a booking is confirmed.</MapDescription>

      <Wrapper ref={mapWrapperRef}>
        {!hideMap && (
          <div data-testid="pdp3-boat-map">
            <StyledBoatMap
              defaultCenter={mapCenter}
              center={mapCenter}
              defaultZoom={11}
              PinImage="/images/location-pin-blue.svg"
            />
          </div>
        )}
      </Wrapper>
    </StyledBoatLocation>
  );
}

const StyledBoatLocation = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #dbdfe5;
  scroll-margin-top: 70px;
  ${mediaMMdMax} {
    padding-top: 35px;
    padding-bottom: 35px;
  }
`;

const MapDescription = styled.div`
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 14px;
  color: #5e696a;
  ${mediaMMdMax} {
    font-size: 12px;
    padding-bottom: 32px;
  }
`;

const Wrapper = styled.div`
  min-height: 500px;

  ${mediaMMdMax} {
    margin: 0 -21px;
    min-height: 375px;
  }
`;

const StyledBoatMap = styled(BoatMap)`
  height: 500px !important;
  margin: 0 !important;

  ${mediaMMdMax} {
    border-radius: 0 !important;
    height: 375px !important;
  }
`;
