import styled, { css, CSSProperties } from 'styled-components';
import { mediaMMdMin, mediaMSmMax } from 'helpers/breakpoints';
import { Box, BoxProps } from './box';

interface TextProps extends BoxProps {
  fWeight?: CSSProperties['fontWeight'];
  fSize?: string;
  lHeight?: string;
  // FIXME color need to be renamed or removed by destructuring
  color?: string;
  // FIXME cursor need to be renamed or removed by destructuring
  cursor?: CSSProperties['cursor'];
  opacity?: number;
  tAlign?: CSSProperties['textAlign'];
  tTransform?: CSSProperties['textTransform'];
  /* FIXME: textDecoration get passed to html attrs too */
  textDecoration?: CSSProperties['textDecoration'];
}

interface TextPropsResponsive extends TextProps {
  mdMin?: TextProps;
  smMax?: TextProps;
}

const buildTextCSS = ({
  fWeight,
  fSize,
  lHeight,
  color,
  tTransform,
  cursor,
  tAlign,
  textDecoration,
  opacity,
}: TextProps) => css`
  font-weight: ${fWeight};
  font-size: ${fSize};
  line-height: ${lHeight};
  color: ${color};
  text-transform: ${tTransform};
  cursor: ${cursor};
  text-align: ${tAlign};
  opacity: ${opacity};
  text-decoration: ${textDecoration};
`;

export const Text = styled(Box)<TextPropsResponsive>`
  ${(props) => buildTextCSS(props)};

  ${mediaMMdMin} {
    ${({ mdMin = {} }) => buildTextCSS(mdMin)};
  }
  ${mediaMSmMax} {
    ${({ smMax = {} }) => buildTextCSS(smMax)};
  }
`;
