import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { Flex, FlexAlignCenter, FlexAlignCenterSpaceBetween, FlexCol, ItemFlex1 } from 'components/primitives/flex';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useBreakpoint } from 'components/BreakpointProvider';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { useAtom, useAtomValue } from 'jotai';
import {
  bookingBoatIdAtom,
  bookingDurationAtom,
  isBookingMultiDayAtom,
  selectedBookingPackageAtom,
  tripFinishFormattedAtom,
  tripStartDayjsAtom,
  tripStartFormattedAtom,
  bookingTripTimeAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useBoatCalendar, useRudderStackOrderTraits } from 'components/forms/bookingWidget/hooks';
import { EMPTY } from 'utils/atomUtils';
import { useTripCalculator } from 'hooks/queries';
import useModal from 'components/remodal/v2/useModal';
import { BoatPackage } from 'swagger/models';
import { dollarsNoCentsWithSymbol } from '../../../helpers/currency';
import BookingCTA from '../../forms/bookingWidget/BookingCTA';
import { isDurationAvailable } from '../../forms/TimePicker/utils';
import CalendarInput, { ArrowWrapper, Input, Label, Value } from '../../calendar/CalendarInput';
import DurationPicker from '../../forms/bookingWidget/DurationPicker';
import { DropDownHeader, SelectIconWrapper, SelectInputValue, SmallLabel } from '../../forms/TimePicker/SelectInput';
import { DesktopWrapper } from '../../calendar/BookingCalendarWrapper';
import { layoutMaxWidth } from './common';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';
import MobileBookingModal from './modals/MobileBookingModal';

type Props = {
  isVisible: boolean;
  hideReviews?: boolean;
  boatId: string;
  primaryPackage: BoatPackage;
};

export default function BottomBar({ isVisible, hideReviews, boatId, primaryPackage }: Props) {
  const router = useRouter();
  const rudderTrack = useRudderStackTrack();
  const rudderStackOrderTraits = useRudderStackOrderTraits();
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  const [bookingDuration, setBookingDuration] = useAtom(bookingDurationAtom);
  const [tripTime, setTripTime] = useAtom(bookingTripTimeAtom);
  const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const bookingBoatId = useAtomValue(bookingBoatIdAtom);
  const tripStart = useAtomValue(tripStartDayjsAtom);
  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);
  const tripFinishFormatted = useAtomValue(tripFinishFormattedAtom);

  const { tripCalculation, isIdle, isLoading } = useTripCalculator();
  const { boatCalendar } = useBoatCalendar({
    startOn: tripStartFormatted,
    endOn: tripFinishFormatted,
    boatId: bookingBoatId,
    packageType: selectedBookingPackage?.package_type,
  });

  const { modal, openModal } = useModal(<MobileBookingModal boatId={boatId} primaryPackage={primaryPackage} />, {
    isOverlay: true,
    trackingName: 'mobile_booking_modal',
  });

  const priceAndDuration = (
    <PriceWrapper>
      <Price>
        {isLoading || isIdle ? (
          <LoadingWrapper>
            <LoadingSpinner color="#72D4BA" />
          </LoadingWrapper>
        ) : (
          dollarsNoCentsWithSymbol(tripCalculation?.pre_tax_and_fees_booking_total || 0)
        )}
      </Price>
      {bookingDuration && (
        <Duration>
          {isBookingMultiDay ? bookingDuration?.label : bookingDuration?.label?.replace('hours', 'hr')} (excl. fees)
        </Duration>
      )}
    </PriceWrapper>
  );

  if (smallScreen) {
    return (
      <Container>
        <ItemFlex1>
          <FlexAlignCenterSpaceBetween>
            {priceAndDuration}
            <BookingCTA boatId={boatId} onClick={openModal} loading={false} />
          </FlexAlignCenterSpaceBetween>
        </ItemFlex1>
        {modal}
      </Container>
    );
  }

  return (
    <CSSTransition in={isVisible} timeout={300} unmountOnExit classNames="BottomBarPDPv3">
      <Container>
        <Content>
          <FlexAlignCenterSpaceBetween>
            <FlexAlignCenter as="ul" gap="24px">
              <li>
                <StyledLink href="#boatInfo">The boat</StyledLink>
              </li>
              <li>
                <StyledLink href="#bookingOptions">Booking options</StyledLink>
              </li>
              {!hideReviews && (
                <li>
                  <StyledLink href="#reviews">Reviews</StyledLink>
                </li>
              )}
              <li>
                <StyledLink href="#boatLocation">Location</StyledLink>
              </li>
            </FlexAlignCenter>

            <BookingWidgetWrapper gap="20px" data-testid="pdp-booking-widget-sticky-nav">
              {priceAndDuration}
              <BookingWidget>
                <Flex>
                  <StyledCalendarInput
                    hideMultiDay={!primaryPackage?.all_day_cents}
                    onChange={(newDate) => {
                      if (isBookingMultiDay) return;

                      if (
                        !isDurationAvailable(
                          dayjs(newDate).format('YYYY-MM-DD'),
                          boatCalendar,
                          selectedBookingPackage?.package_type,
                          tripTime?.value,
                          bookingDuration?.value
                        )
                      ) {
                        setBookingDuration(EMPTY);
                        setTripTime(null);
                      }
                    }}
                  />
                  <DividerLine />
                  <StyledDurationPicker
                    onChange={(duration) => {
                      if (
                        !isDurationAvailable(
                          tripStart.format('YYYY-MM-DD'),
                          boatCalendar,
                          selectedBookingPackage?.package_type,
                          tripTime?.value,
                          duration.value
                        )
                      ) {
                        setTripTime(null);
                      }
                    }}
                    setter={setBookingDuration}
                    defaultValue={bookingDuration}
                  />
                  <BookingCTA
                    boatId={boatId}
                    onClick={() => {
                      rudderTrack('request_to_book_start', {
                        type: 'horizontal_sticky_bar',
                        ...rudderStackOrderTraits,
                      });
                      router.push({
                        hash: '#bookingWidget',
                      });
                    }}
                    loading={false}
                  />
                </Flex>
              </BookingWidget>
            </BookingWidgetWrapper>
          </FlexAlignCenterSpaceBetween>
        </Content>
      </Container>
    </CSSTransition>
  );
}

const LoadingWrapper = styled.div`
  width: 25px;
  height: 29px;
  transform: scale(0.8);
  padding-top: 15px;
  padding-left: 10px;
`;

const DividerLine = styled.div`
  width: 1px;
  height: 35px;
  background: rgba(219, 223, 229, 0.5);
  align-self: center;
`;

const Container = styled(FlexAlignCenter)`
  z-index: 10;
  isolation: isolate;
  height: 100px;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 20px 0px #0000001a;
  transition: transform 300ms ease-in-out;

  ${mediaMLgMin} {
    transform: translateY(-100%);
  }

  ${mediaMMdMax} {
    top: unset;
    bottom: 0;
  }

  &.BottomBarPDPv3-enter {
    transform: translateY(-100%);
  }

  &.BottomBarPDPv3-enter-done {
    transform: translateY(0%);
  }

  &.BottomBarPDPv3-enter-active {
    transform: translateY(0%);
  }

  &.BottomBarPDPv3-exit-active {
    transform: translateY(-100%);
  }

  ${mediaMMdMax} {
    padding: 0 24px;
  }
`;

const Content = styled.div`
  ${layoutMaxWidth};
  width: 100%;
`;

const StyledLink = styled.a`
  color: #17233c !important;
  font-size: 14px;
  font-weight: 500;
`;

const StyledDurationPicker = styled(DurationPicker)`
  padding: 0 20px;
  align-items: center;
  display: flex;

  ${DropDownHeader} {
    flex-flow: row-reverse;
    gap: 10px;
  }

  ${SelectInputValue} {
    padding-top: 5px;
  }

  ${SelectIconWrapper} {
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${SmallLabel} {
    display: none;
  }
`;

const StyledCalendarInput = styled(CalendarInput)`
  padding: 0 20px;

  ${Input} {
    flex-flow: row-reverse;
    gap: 10px;
    align-items: center;
  }

  //border-right: 1px solid rgba(219, 223, 229, 0.5);

  ${DesktopWrapper} {
    transform: translate3d(0, 20px, 0);
    right: 0;
  }

  ${Label} {
    position: initial;
    color: #17233c;
    padding-top: 2px;
    &.active {
      display: none;
    }
  }

  ${ArrowWrapper} {
    padding-bottom: 0;
  }

  ${Value} {
    padding-top: 2px;
  }
`;

const BookingWidget = styled.div`
  border: 1px solid #8e9697;
  border-radius: 66px;
  padding: 5px;

  & > div > div > button {
    padding: 0 25px;
    border-radius: 54px;
  }
`;

const BookingWidgetWrapper = styled(FlexAlignCenter)`
  position: relative;
`;

const PriceWrapper = styled(FlexCol)`
  align-items: end;

  ${mediaMMdMax} {
    align-items: flex-start;
  }
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const Duration = styled.div`
  color: #8e9697;
  font-weight: 500;
`;
