import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import dayjs from 'dayjs';
import OutstandingOffer from 'components/forms/bookingWidget/OutstandingOffer';
import { useTripCalculator } from 'hooks/queries';
import boatsetterPaths from 'utils/boatsetterPaths';
import { Flex, FlexColMdMax } from '../../primitives/flex';
import StarRating from './StarRating';
import { CaptainIcon, LengthIcon, PassengersIcon, ResponseRateIcon } from './icons';
import { captainStatusMap } from './constants';
import BoatInfo from './BoatInfo';
import { useBoatDetails } from './hooks';
import CancellationInfo from '../../forms/bookingWidget/CancellationInfo';
import { useBreakpoint } from '../../BreakpointProvider';
import { STATES } from '../../forms/boat/constants';

type Props = {
  boatId: string;
  isBoatCaptained: boolean;
};

export default function BoatHeader({ boatId, isBoatCaptained }: Props) {
  const { boatDetails, isLoading } = useBoatDetails(boatId);
  const { location, is_fishing, boat_category, type } = boatDetails;

  const { tripCalculation } = useTripCalculator();
  const [cityUrl, setCityUrl] = useState({
    text: '',
    url: null,
  });
  const cityUrlChecked = useRef(false);

  const [stateUrl, setStateUrl] = useState({
    text: '',
    url: null,
  });
  const stateUrlChecked = useRef(false);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const selectedBoatCategory = getBoatCategory(is_fishing, isBoatCaptained, type, boat_category);

  useEffect(() => {
    if (location?.state && !stateUrlChecked.current) {
      const resultStateValue = STATES.find((item) => item.abbr === location?.state)?.label;
      if (!resultStateValue) {
        setStateUrl({
          text: location?.state,
          url: null,
        });
        stateUrlChecked.current = true;
      } else {
        const url = `${selectedBoatCategory.categoryUrl}/${location.state.toLowerCase()}--united-states`;
        setStateUrl({
          text: location?.state,
          url: null,
        });
        stateUrlChecked.current = true;

        fetch(url)
          .then((response) => {
            if (response.status === 200) {
              setStateUrl({
                text: location?.state,
                url,
              });
            } else {
              setStateUrl({
                text: location?.state,
                url: null,
              });
            }
          })
          .catch(() => {
            setStateUrl({
              text: location?.state,
              url: null,
            });
          });
      }
    }
  }, [location, selectedBoatCategory]);

  useEffect(() => {
    if (location?.city && !cityUrlChecked.current) {
      const url = `${selectedBoatCategory.categoryUrl}/${location.city
        ?.toLowerCase()
        ?.replace(/ /g, '-')
        ?.replace(/\./g, '')}--${location.state?.toLowerCase()}--united-states`;

      setCityUrl({
        text: `${location.city}`,
        url: null,
      });
      cityUrlChecked.current = true;

      fetch(url)
        .then((response) => {
          if (response.status === 200) {
            setCityUrl({
              text: `${location.city}`,
              url,
            });
          } else {
            setCityUrl({
              text: `${location.city}`,
              url: null,
            });
          }
        })
        .catch(() => {
          setCityUrl({
            text: `${location.city}`,
            url: null,
          });
        });
    }
  }, [location, selectedBoatCategory]);

  if (isLoading) {
    return null;
  }

  return (
    <InfoWrapper data-testid="pdp-boat-header">
      {smallScreen && <OutstandingOffer boatId={boatId} />}
      <Title data-testid="pdp-boat-header-title">{boatDetails.listing_tagline}</Title>
      <LocReviewWrapper>
        <div data-testid="pdp-boat-header-rating">
          {boatDetails.rating > 0 && (
            <StarRating rating={boatDetails.rating} reviewCount={boatDetails.completed_booking_count} />
          )}
        </div>
        <Location
          hasRating={boatDetails.review_count > 0 && boatDetails.rating > 0}
          data-testid="pdp-boat-header-location"
        >
          <LocationLink
            data-testid="pdp-boat-header-location-city"
            as={cityUrl.url ? 'a' : 'span'}
            link={!!cityUrl.url}
            href={cityUrl.url}
          >
            {cityUrl.text}
          </LocationLink>
          ,&nbsp;
          <LocationLink
            data-testid="pdp-boat-header-location-state"
            as={stateUrl.url ? 'a' : 'span'}
            link={!!stateUrl.url}
            href={stateUrl.url}
          >
            {stateUrl.text}
          </LocationLink>
        </Location>
      </LocReviewWrapper>
      <BoatKeyFeatures>
        <KeyFeature data-testid="pdp-boat-header-key-feature-length">
          <KeyIcon>
            <LengthIcon />
          </KeyIcon>
          <KeyTitle>{boatDetails.length} ft</KeyTitle>
          <KeyDescription>Boat length</KeyDescription>
        </KeyFeature>
        <KeyFeature data-testid="pdp-boat-header-key-feature-passengers">
          <KeyIcon>
            <PassengersIcon />
          </KeyIcon>
          <KeyTitle>Up to {boatDetails.capacity}</KeyTitle>
          <KeyDescription>Passengers</KeyDescription>
        </KeyFeature>
        <KeyFeature data-testid="pdp-boat-header-key-feature-package-type">
          <KeyIcon>
            <CaptainIcon />
          </KeyIcon>
          <KeyTitle>{captainStatusMap[boatDetails.captain_option]?.title}</KeyTitle>
          <KeyDescription>{captainStatusMap[boatDetails.captain_option]?.description}</KeyDescription>
        </KeyFeature>
        <KeyFeature data-testid="pdp-boat-header-key-feature-owner-response-rate">
          <KeyIcon>
            <ResponseRateIcon />
          </KeyIcon>
          <KeyTitle>{boatDetails.primary_manager?.response_rate}</KeyTitle>
          <KeyDescription>Owner’s response rate</KeyDescription>
        </KeyFeature>
      </BoatKeyFeatures>
      {tripCalculation?.free_cancelation_until &&
        dayjs(tripCalculation?.free_cancelation_until).diff(dayjs(), 'day') !== 0 &&
        smallScreen && <CancellationInfo />}
      <BoatInfo
        description={boatDetails.listing_description}
        owner={boatDetails.primary_manager}
        showTopOwnerBadge={boatDetails.primary_manager?.top_owner_badge}
        showNewOwnerBadge={boatDetails.primary_manager?.new_owner_badge}
        boatId={boatId}
      />
    </InfoWrapper>
  );
}

const LocationLink = styled.a<{ link: boolean }>`
  ${({ link }) =>
    link &&
    css`
      color: #17233c !important;
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export function getBoatCategory(isFishingBoat: boolean, isBoatCaptained: boolean, boatType: string, category: string) {
  if (
    isFishingBoat &&
    category !== 'sail' &&
    category !== 'catamaran' &&
    category !== 'motor_yacht' &&
    category !== 'mega_yacht'
  ) {
    if (isBoatCaptained) {
      return {
        categoryUrl: boatsetterPaths.fishingCharters,
        categoryText: 'Fishing charters',
      };
    }
    return {
      categoryUrl: boatsetterPaths.fishingBoatRentals,
      categoryText: 'Fishing boat rentals',
    };
  }

  if (boatType === 'sail' && category !== 'catamaran') {
    return {
      categoryUrl: boatsetterPaths.sailboatRentals,
      categoryText: 'Sailboat rentals',
    };
  }

  switch (category) {
    case 'motor_yacht':
    case 'mega_yacht':
      return {
        categoryUrl: boatsetterPaths.yachtRentals,
        categoryText: 'Yacht rentals',
      };
    case 'catamaran':
      return {
        categoryUrl: boatsetterPaths.catamaranRentals,
        categoryText: 'Catamaran rentals',
      };
    case 'center_console':
      return {
        categoryUrl: boatsetterPaths.centerConsoleRentals,
        categoryText: 'Center console rentals',
      };
    default:
      return {
        categoryUrl: boatsetterPaths.boatRentals,
        categoryText: 'Boat rentals',
      };
  }
}

const LocReviewWrapper = styled(FlexColMdMax)`
  ${mediaMMdMax} {
    gap: 5px;
  }
`;

const KeyIcon = styled.div`
  padding-bottom: 5px;
`;

const KeyTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const KeyDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #5e696a;
`;

const Location = styled.div<{ hasRating: boolean }>`
  font-weight: 500;

  ${mediaMMdMax} {
    font-size: 14px;
  }

  ${({ hasRating }) =>
    hasRating &&
    css`
      ${mediaMMdMax} {
        order: -1;
      }
      ${mediaMLgMin} {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #dbdfe5;
      }
    `}
`;

const InfoWrapper = styled.div`
  padding-top: 25px;
  ${mediaMMdMax} {
    padding-top: 20px;
  }
`;

const Title = styled.h1`
  font-size: 23px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  padding-bottom: 20px;

  ${mediaMLgMin} {
    font-size: 38px;
  }

  ${mediaMMdMax} {
    line-height: 1.2;
  }
`;

const KeyFeature = styled.div`
  padding-left: 30px;
  padding-right: 30px;

  border-left: 1px solid #dbdfe5;

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const BoatKeyFeatures = styled(Flex)`
  padding-top: 40px;

  ${mediaMMdMax} {
    border: 1px solid #dbdfe5;
    border-radius: 8px;
    padding: 20px;
    flex-wrap: wrap;
    margin-top: 30px;

    ${KeyFeature} {
      width: 50%;
      border-left: none;
      padding: 0;

      &:nth-child(-n + 2) {
        border-bottom: 1px solid #dbdfe5;
        padding-bottom: 18px;
      }
      &:nth-last-child(-n + 2) {
        padding-top: 18px;
      }
      &:nth-child(even) {
        border-left: 1px solid #dbdfe5;
        padding-left: 24px;
      }
      &:nth-child(odd) {
        padding-right: 24px;
      }
    }
  }
`;
