import styled from 'styled-components';
import { Box } from 'components/primitives/box';
import { useAtomValue } from 'jotai';
import {
  bookingDurationAtom,
  bookingPassengersAtom,
  selectedBookingPackageAtom,
  bookingTripTimeAtom,
  tripStartFormattedAtom,
  isBookingMultiDayAtom,
  bookingCaptainPublicIdAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import BookingSpecs from 'components/checkout/v2/BookingSpecs';
import { useBoatDetails } from '../../pdp/v3/hooks';

type BookingTermsProps = {
  securityDepositCents: number;
  cancellationPolicy: string;
  fuelPolicy: string;
  boatId: string;
};

export default function BookingTerms({
  securityDepositCents,
  cancellationPolicy,
  fuelPolicy,
  boatId,
}: BookingTermsProps) {
  const { boatDetails } = useBoatDetails(boatId);
  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const tripTime = useAtomValue(bookingTripTimeAtom);
  const bookingDuration = useAtomValue(bookingDurationAtom);
  const passengersCount = useAtomValue(bookingPassengersAtom);
  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);
  const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
  const bookingCaptainId = useAtomValue(bookingCaptainPublicIdAtom);
  const boatOwnerId = boatDetails?.primary_manager?.id;

  return (
    <Box padding="15px 0 0">
      <TermsHeading>Terms</TermsHeading>
      <StyledBookingSpecs
        testIdPrefix="pdp-booking-widget-pre-checkout-additional-links-booking-spec"
        specsDataObj={{
          cancellationPolicy,
          fuelPolicy,
          securityDepositCents,
          charterTypeParams: {
            tz: boatDetails.time_zone,
            isBookingMultiDay,
            passengers: passengersCount,
            boatId: boatDetails.id,
            selectedPackage: {
              id: selectedBookingPackage?.id,
              charter_agreement_type: selectedBookingPackage?.charter_agreement_type,
              package_type: selectedBookingPackage?.package_type,
            },
            tripStart: tripStartFormatted,
            tripTime: tripTime?.value,
            duration: bookingDuration?.name,
            selectedCaptain: {
              id: bookingCaptainId,
              boatOwnerId,
            },
          },
        }}
      />
    </Box>
  );
}

const TermsHeading = styled.div`
  font-weight: 600;
  padding-bottom: 5px;
  font-size: 14px;
`;

const StyledBookingSpecs = styled(BookingSpecs)`
  li {
    font-size: 12px !important;
  }
`;
