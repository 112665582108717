import dayjs from 'dayjs';
import { FormProvider } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { mediaMMdMax } from 'helpers/breakpoints';
import { Box } from 'components/primitives/box';
import useFormWithErrorTracking from 'hooks/useFormWithErrorTracking';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  bookingDurationAtom,
  bookingCaptainIdAtom,
  isBookingMultiDayAtom,
  bookingMessageAtom,
  bookingPassengersAtom,
  selectedBookingPackageAtom,
  tripFinishDayjsAtom,
  tripFinishFormattedAtom,
  tripStartDayjsAtom,
  tripStartFormattedAtom,
  bookingTripTimeAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import {
  useBoatCalendar,
  useInitializeBookingFormData,
  useRudderStackOrderTraits,
} from 'components/forms/bookingWidget/hooks';
import { EMPTY } from 'utils/atomUtils';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { DomesticV2BookingsInquiryPostRequest } from 'swagger/models';
import { useMutation } from 'react-query';
import { useTripCalculator } from 'hooks/queries';
import { BookingsApi } from 'swagger/apis/bookings-api';
import ChangeCaptainModal from 'components/pdp/v3/modals/ChangeCaptainModal';
import useModal from 'components/remodal/v2/useModal';
import { useUserDetails } from 'auth/mutations/userHooks';
import useLazySnackbar from 'components/banners/useLazySnackbar';
import Textarea from 'components/ui/Textarea/Textarea';
import Button from 'components/ui/Button/Button';
import useIncompleteUserProcessing from 'components/auth/hooks/useIncompleteUserProcessing';
import useRedirectToExistingBooking from 'components/checkout/v2/hooks/useRedirectToExistingBooking';
import { removeLocalBookingData } from 'components/checkout/v2/utils';
import Cookies from 'js-cookie';
import useAnalyticsTrack from 'hooks/useAnalyticsTrack';
import { useLocalStorage } from 'beautiful-react-hooks';
import boatsetterPaths from 'utils/boatsetterPaths';
import { isDurationAvailable } from '../TimePicker/utils';
import DurationPicker from './DurationPicker';
import TimePicker from '../TimePicker/TimePicker';
import PackageTypePicker from './PackageTypePicker';
import PassengerCounter from './PassengerCounter';
import { Flex, FlexAlignCenterSpaceBetween, FlexCol, FlexJustifySpaceBetween } from '../../primitives/flex';
import { dollarsNoCentsWithSymbol, dollarsWithCentsWithSymbol } from '../../../helpers/currency';
import BookingCTA from './BookingCTA';
import {
  apiParamsHourNameMap,
  apiParamsHourNameMapReverse,
  createDurationOption,
  createMultiDayDurationOption,
} from './utils';
import { constructBookingQueryString, valueEmpty, sha256HashString } from '../../../helpers';
import { useBoatDetails } from '../../pdp/v3/hooks';
import CalendarInput from '../../calendar/CalendarInput';
import { DesktopWrapper } from '../../calendar/BookingCalendarWrapper';
import { useBreakpoint } from '../../BreakpointProvider';
import useRudderStackTrack from '../../../hooks/useRudderStackTrack';

const bookingsApi = new BookingsApi(OpenApiConfiguration);

const BookingForm = ({
  boatId,
  isMessageOwner = false,
  isExpanded,
  primaryPackage,
  setExpanded = null,
  onSuccess = null,
  isModal = false,
}) => {
  const { enqueueSnackbar } = useLazySnackbar();
  const router = useRouter();
  const rudderTrack = useRudderStackTrack();
  const eventIntakeTrack = useAnalyticsTrack();
  const [tripStart, setTripStart] = useAtom(tripStartDayjsAtom);
  const tripFinish = useAtomValue(tripFinishDayjsAtom);
  const tripStartFormatted = useAtomValue(tripStartFormattedAtom);
  const tripFinishFormatted = useAtomValue(tripFinishFormattedAtom);
  const [tripTime, setTripTime] = useAtom(bookingTripTimeAtom);
  const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
  const passengersCount = useAtomValue(bookingPassengersAtom);
  const [bookingMessage, setBookingMessage] = useAtom(bookingMessageAtom);
  const [bookingDuration, setBookingDuration] = useAtom(bookingDurationAtom);

  const [selectedBookingPackage, setSelectedBookingPackage] = useAtom(selectedBookingPackageAtom);
  const setTripFinish = useSetAtom(tripFinishDayjsAtom);

  const bookingCaptainId = useAtomValue(bookingCaptainIdAtom);

  const rudderStackOrderTraits = useRudderStackOrderTraits();
  const { tripCalculation } = useTripCalculator();

  const { userId, isAuthenticated } = useUserDetails();
  const { boatDetails } = useBoatDetails(boatId);
  const { handlePossibleIncompleteAccountInApp } = useIncompleteUserProcessing();
  const [galacticaSession] = useLocalStorage('galactica_session', '');

  const { boatCalendar } = useBoatCalendar({
    startOn: tripStart?.startOf('month')?.format('YYYY-MM-DD'),
    endOn: tripFinish?.add(1, 'month')?.format('YYYY-MM-DD'),
    boatId,
    packageType: selectedBookingPackage?.package_type,
  });

  const dayIsAvailable = !valueEmpty(boatCalendar) && !!boatCalendar[tripStart?.format('YYYY-MM-DD')]?.available;

  const {
    modal: changeCaptainModal,
    openModal: openChangeCaptainModal,
    closeModal,
  } = useModal(<ChangeCaptainModal boatId={boatId} />, {
    isOnRight: true,
    notScrollAble: true,
    trackingName: 'change_captain_modal',
  });

  interface InquiryPostRequest extends DomesticV2BookingsInquiryPostRequest {
    captain_id?: string;
  }

  const { mutate: createBookingInquiryMutate, isLoading: processing } = useMutation(
    ({ body }: { body: InquiryPostRequest }) => bookingsApi.domesticV2BookingsInquiryPost(body),
    {
      onSuccess({ data }: { data: any }) {
        rudderTrack('booking_inquiry_created', {
          booking_id: data?.id,
        });
        rudderTrack('customSiteEvent', {
          category: 'bookingMessageOwnerSent',
          action: 'booking message owner',
          label: 'SendMessage',
          // Defensive due to the any type
          renterEmail: data?.renter?.email && sha256HashString(data.renter.email),
        });
        removeLocalBookingData(false, {});

        rudderTrack('message_owner_success', {
          ...rudderStackOrderTraits,
          booking_id: data?.id,
        });
        onSuccess?.(data);
      },
      onError({
        response: {
          data: {
            error_message,
            error_code,
            data: { message },
          },
        },
      }) {
        if (error_code === 'validation_error' && Array.isArray(message) && message[0] === 'contained spam') {
          setError('message', {
            message:
              'To protect users, messages that contain certain words, phone numbers, email addresses or website links are not allowed.',
          });
          return;
        }
        enqueueSnackbar(error_message, { variant: 'error' });
      },
    }
  );

  const methods = useFormWithErrorTracking({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      trip_start: null,
      duration: null,
      trip_time: null,
      message: null,
      passengers: null,
    },
  });
  const { handleSubmit, register, errors, setValue, watch, setError } = methods;

  useInitializeBookingFormData(boatId, primaryPackage);
  const existingBookingId = useRedirectToExistingBooking(boatId);

  // sync store values with form validation
  useEffect(() => {
    setValue('trip_start', tripStartFormatted, { shouldValidate: !!tripStart });
    setValue('trip_time', tripTime?.value, { shouldValidate: !!tripTime });
    setValue('duration', bookingDuration?.value, { shouldValidate: !!bookingDuration });
    setValue('message', bookingMessage, { shouldValidate: !!bookingMessage });
    setValue('passengers', passengersCount, { shouldValidate: !!passengersCount });
  }, [tripStart, tripTime, bookingDuration, bookingMessage, passengersCount]);

  const onSubmit = async (values) => {
    const addCaptainId = bookingCaptainId && boatDetails.captain_network_enabled;

    const checkoutParameters = {
      boat_public_id: boatId,
      tz: boatDetails.time_zone,
      package_public_id: selectedBookingPackage.id,
      package_type: selectedBookingPackage.package_type,
      passengers: passengersCount,
      is_multi_day: isBookingMultiDay,
      ...values,
      duration: apiParamsHourNameMap[values.duration],
      trip_start: values.trip_start,
      trip_finish: tripFinishFormatted || tripStartFormatted,
      ...(addCaptainId && { captain_public_id: bookingCaptainId }),
    };

    if (isMessageOwner) {
      createBookingInquiryMutate({
        body: {
          message: values.message,
          trip_time: values.trip_time,
          duration: apiParamsHourNameMap[values.duration],
          package_id: selectedBookingPackage.id,
          trip_start: values.trip_start,
          trip_finish: tripFinishFormatted || tripStartFormatted,
          passengers: values.passengers,
          ...(addCaptainId && { captain_id: bookingCaptainId }),
        },
      });

      return;
    }

    if (!isMessageOwner && 'is_captain_available' in tripCalculation && !tripCalculation.is_captain_available) {
      enqueueSnackbar(
        'The captain assigned is not available during the time selected. Please assign another captain.',
        {
          variant: 'error',
          autoHideDuration: 4000,
          onClick: openChangeCaptainModal,
        }
      );
    }

    const urlParameters = constructBookingQueryString(checkoutParameters);
    const bookNowUrl = `/a/bookings/book_now?${urlParameters}`;
    const signupUrl = `${boatsetterPaths.signUp}?redirect_to=${encodeURIComponent(bookNowUrl)}`;

    if (isAuthenticated) {
      rudderTrack('request_to_book_success', {
        type: 'expanded_floating',
        ...rudderStackOrderTraits,
      });
    }

    // if not authenticated go to signup
    if (!isAuthenticated) {
      router.push(signupUrl);
      return;
    }

    const completeAccount = await handlePossibleIncompleteAccountInApp(bookNowUrl);

    // if account is completed go to book now
    if (completeAccount) {
      if (existingBookingId) {
        router.push(`/a/bookings/${existingBookingId}/book_now`);
        return;
      }

      closeModal();
      router.push(bookNowUrl);
    }
  };

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const trackRequestToBookStart = () => {
    rudderTrack('request_to_book_start', {
      type: isExpanded ? 'expanded_floating' : 'initial_floating',
      ...rudderStackOrderTraits,
    });

    if (selectedBookingPackage.instant_bookable) {
      eventIntakeTrack(
        'UserDidInstantBooking',
        {
          anonymousId: Cookies.get('uuid') || '',
          sessionId: galacticaSession || '',
          appScreen: router.pathname,
        },
        {
          boatId,
        }
      );
    } else {
      eventIntakeTrack(
        'UserRequestedBooking',
        {
          anonymousId: Cookies.get('uuid') || '',
          sessionId: galacticaSession || '',
          appScreen: router.pathname,
        },
        {
          boatId,
        }
      );
    }
  };

  const messageOwnerWrapperRef = useRef();

  const viewerIsOwner = userId === boatDetails?.primary_manager?.id;

  return (
    <FormProvider {...methods}>
      <Form as="form" onSubmit={handleSubmit(onSubmit)} isModal={isModal}>
        <InputWrap noBorder={isModal}>
          <StyledCalendarInput
            label="Date"
            monthsToShow={!smallScreen && isMessageOwner ? 1 : 2}
            modalVersion={isModal}
            hidePrice
            hideMobileHeader
            hideMultiDay={!primaryPackage?.all_day_cents}
            error={errors?.trip_start?.message}
            onMultiDayChange={(newDate, multiDay) => {
              if (!multiDay) {
                setBookingDuration(
                  createDurationOption(
                    apiParamsHourNameMapReverse[boatDetails.cheapest_package.price_name],
                    boatDetails.cheapest_package.price_name
                  )
                );
                setSelectedBookingPackage(boatDetails.packages.find((p) => p.id === boatDetails.cheapest_package.id));
              } else {
                setBookingDuration(createMultiDayDurationOption(newDate, newDate));
              }
            }}
            onChange={(newDate) => {
              router.push(
                {
                  query: {
                    ...router.query,
                    start_period: isBookingMultiDay
                      ? dayjs(newDate.startDate).format('YYYY-MM-DD')
                      : dayjs(newDate).format('YYYY-MM-DD'),
                    end_period: isBookingMultiDay ? dayjs(newDate.endDate).format('YYYY-MM-DD') : undefined,
                  },
                },
                undefined,
                { shallow: true, scroll: false }
              );

              if (isBookingMultiDay) return;

              if (
                !isDurationAvailable(
                  dayjs(newDate).format('YYYY-MM-DD'),
                  boatCalendar,
                  selectedBookingPackage?.package_type,
                  tripTime?.value,
                  bookingDuration?.value
                )
              ) {
                setBookingDuration(EMPTY);
                setTripTime(EMPTY);
              }
            }}
          />

          <input type="hidden" ref={register({ required: 'Please enter your trip date' })} name="trip_start" />
        </InputWrap>
        <InputWrap noBorder={isModal}>
          <DurationPicker
            modalVersion={isModal}
            error={errors?.duration?.message}
            onChange={(duration) => {
              if (
                !isDurationAvailable(
                  tripStartFormatted,
                  boatCalendar,
                  selectedBookingPackage?.package_type,
                  tripTime?.value,
                  duration.value
                )
              ) {
                setTripTime(EMPTY);
              }
            }}
            setter={setBookingDuration}
            defaultValue={bookingDuration}
          />
          <input type="hidden" ref={register({ required: 'Please select duration' })} name="duration" />
        </InputWrap>
        <InputWrap noBorder>
          <TimePicker
            modalVersion={isModal}
            error={errors?.trip_time?.message}
            calendar={boatCalendar}
            onChange={() => {
              if (setExpanded) setExpanded(true);
            }}
            useDuration
            editable
          />

          <input type="hidden" ref={register({ required: 'Please enter start time' })} name="trip_time" />
        </InputWrap>

        {isExpanded && (
          <>
            <PackageTypePicker
              onChange={(packageType) => {
                // reset duration and time if the new package type doesn't have the previous package
                if (!packageType[bookingDuration?.name]) {
                  setBookingDuration(EMPTY);
                  setTripTime(EMPTY);
                  if (isBookingMultiDay) {
                    setTripStart(EMPTY);
                    setTripFinish(EMPTY);
                  }
                }
              }}
              captainStatus={boatDetails.captain_option}
            />

            <Box padding={`${isModal ? 0 : '20px'} 0 0`}>
              <PassengerCounter />
              <input type="hidden" ref={register({ required: 'Please enter passenger amount' })} name="passengers" />
            </Box>

            {isMessageOwner && (
              <FlexCol data-testid="textarea-wrapper" ref={messageOwnerWrapperRef}>
                <Textarea
                  onChange={(e) => {
                    setBookingMessage(e.target.value);
                  }}
                  hasError={errors.message?.message}
                  style={{ minHeight: '150px', resize: 'none' }}
                  inputRef={register({
                    required: 'Please enter your message',
                  })}
                  hasValue={!valueEmpty(watch('message'))}
                  name="message"
                  placeholder="Tell the owner about your trip and boating experience..."
                  label="Your message"
                />
                <MessageError>{errors.message?.message}</MessageError>
              </FlexCol>
            )}
            {isMessageOwner && !smallScreen && (
              <PricingSection gap="5px">
                <MessageOwnerPricing>
                  <FlexCol>
                    <div className="total">Booking total</div>
                    <Fees>Additional fees will show at checkout</Fees>
                  </FlexCol>
                  <div className="totalAmount">
                    {dollarsWithCentsWithSymbol(tripCalculation?.pre_tax_and_fees_booking_total || 0)}
                  </div>
                </MessageOwnerPricing>
              </PricingSection>
            )}

            {!isMessageOwner && (
              <PricingSection gap="5px">
                <FlexAlignCenterSpaceBetween>
                  <div>Boat price</div>
                  <div data-testid="pdp-booking-widget-boat-price-value">
                    {dollarsWithCentsWithSymbol(tripCalculation?.boat_price)}
                  </div>
                </FlexAlignCenterSpaceBetween>
                {!!tripCalculation?.captain_fee && (
                  <FlexAlignCenterSpaceBetween>
                    <div>Captain price</div>
                    <div data-testid="pdp-booking-widget-captain-price-value">
                      {dollarsWithCentsWithSymbol(tripCalculation?.captain_fee)}
                    </div>
                  </FlexAlignCenterSpaceBetween>
                )}
                <BookingTotal>
                  <FlexCol>
                    <div>Booking total</div>
                    <Fees>Additional fees will show at checkout</Fees>
                  </FlexCol>
                  <div data-testid="pdp-booking-widget-booking-total-value">
                    {dollarsWithCentsWithSymbol(tripCalculation?.pre_tax_and_fees_booking_total || 0)}
                  </div>
                </BookingTotal>
              </PricingSection>
            )}
          </>
        )}

        <ButtonContainer data-testid="pdp-booking-widget-submit-container">
          {isMessageOwner ? (
            <Flex gap="40px">
              {smallScreen && (
                <PriceWrapper>
                  <Price>{dollarsNoCentsWithSymbol(tripCalculation?.pre_tax_and_fees_booking_total || 0)}</Price>
                  <Duration>
                    {isBookingMultiDay ? bookingDuration?.label : bookingDuration?.label?.replace('hours', 'hr')} (excl.
                    fees)
                  </Duration>
                </PriceWrapper>
              )}
              <Button
                fullWidth
                loading={processing}
                disabled={viewerIsOwner || processing}
                text={viewerIsOwner ? 'you own this boat' : 'send message'}
                type="submit"
              />
            </Flex>
          ) : (
            <BookingCTA
              onClick={trackRequestToBookStart}
              loading={processing}
              boatId={boatId}
              disabled={!dayIsAvailable}
            />
          )}
        </ButtonContainer>
      </Form>

      {changeCaptainModal}
    </FormProvider>
  );
};

const PriceWrapper = styled(FlexCol)`
  align-items: end;
  min-width: 140px;

  ${mediaMMdMax} {
    align-items: flex-start;
  }
`;

const Price = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const Duration = styled.div`
  color: #8e9697;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  ${mediaMMdMax} {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 20px 0px #0000001a;
    padding: 22px 24px;
    background-color: #fff;
  }
`;

const MessageError = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #bc350a;
  max-width: 340px;
`;

const MessageOwnerPricing = styled(FlexJustifySpaceBetween)`
  padding-bottom: 15px;

  .total {
    font-weight: 600;
  }

  .totalAmount {
    font-size: 18px;
    font-weight: 700;
  }
`;

const StyledCalendarInput = styled(CalendarInput)`
  ${DesktopWrapper} {
    right: 0;
    transform: translateY(-50px);
  }

  ${({ monthsToShow }) =>
    monthsToShow === 1 &&
    css`
      ${DesktopWrapper} {
        transform: translateY(1px) translateX(50%);
        right: 50%;
        //right: initial;
      }
    `}
`;

const InputWrap = styled.div<{ noBorder: boolean }>`
  padding: 10px 0 5px 0;
  border-bottom: ${({ noBorder }) => (noBorder ? 'unset' : '1px solid rgba(219, 223, 229, 0.5)')};
`;

const Fees = styled.div`
  color: #8e9697;
  font-size: 10px;
  font-weight: initial;
  ${mediaMMdMax} {
    padding-top: 5px;
  }
`;

const BookingTotal = styled(FlexJustifySpaceBetween)`
  border-top: 1px solid rgba(219, 223, 229, 0.5);
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 25px;
  font-weight: 600;
`;

const PricingSection = styled(FlexCol)`
  padding-top: 30px;
`;

const Form = styled(FlexCol)<{ isModal: boolean }>`
  ${({ isModal }) =>
    isModal &&
    css`
      gap: 15px;

      ${InputWrap} {
        padding: 0;
      }

      ${PricingSection} {
        padding-top: 20px;
      }
    `}
`;

export const DurationIcon = () => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="10.5" r="6.9" stroke="#5E696A" strokeWidth="1.2" />
    <line x1="7.5" y1="4" x2="7.5" y2="2" stroke="#5E696A" />
    <line x1="7.5" y1="10.5" x2="7.5" y2="6.5" stroke="#5E696A" strokeLinecap="round" />
    <line x1="13.8828" y1="6.79892" x2="14.7488" y2="6.29892" stroke="#5E696A" />
    <line x1="6.6" y1="1.4" x2="8.4" y2="1.4" stroke="#5E696A" strokeWidth="1.2" strokeLinecap="round" />
    <line
      x1="14.8196"
      y1="5.21962"
      x2="15.7196"
      y2="6.77846"
      stroke="#5E696A"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <circle cx="7.5" cy="11" r="1" fill="#5E696A" />
  </svg>
);

export default BookingForm;
