import { Fragment } from 'react';
import styled from 'styled-components';
import { useBreakpoint } from 'components/BreakpointProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { mediaMMdMax } from 'helpers/breakpoints';
import { useSimilarBoatsQuery } from 'components/pdp/v3/hooks';
import { getBoatCardUrl } from 'components/search/v2/utils';
import { Boat } from 'swagger/models';
import BoatCard from 'components/search/v2/BoatCard/BoatCard';
import { SubHeading } from './common';
import { Flex } from '../../primitives/flex';

const renderBoatCard = (boat, index) => (
  <BoatCard
    key={index}
    boat={boat}
    index={index}
    url={getBoatCardUrl({
      boatId: boat.boat_public_id,
      pos: index,
    })}
  />
);

export default function SimilarBoats({ boatId }: { boatId: string }) {
  const { data: similarBoatsData, isLoading } = useSimilarBoatsQuery(boatId);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm || breakpoints.md;

  // @ts-ignore
  const similarBoats = similarBoatsData?.data?.data?.filter((b) => b.boat_public_id !== boatId) as Boat[];

  if (similarBoats?.length === 0) return null;

  return (
    <div data-testid="pdp-similar-boats">
      {!isLoading && (
        <StyledSimilarBoats>
          <SubHeading>Similar boats</SubHeading>
          {!smallScreen ? (
            <Flex gap="25px">
              {similarBoats?.slice(0, 3)?.map((boat, index) => (
                <Fragment key={index}>{renderBoatCard(boat, index)}</Fragment>
              ))}
            </Flex>
          ) : (
            <StyledSwiper spaceBetween={22} slidesPerView={1.25}>
              {(similarBoats || []).map((boat, index) => (
                <SwiperSlide key={boat.id} data-testid="pdp-similar-boats-boat-card">
                  <Flex>{renderBoatCard(boat, index)}</Flex>
                </SwiperSlide>
              ))}
            </StyledSwiper>
          )}
        </StyledSimilarBoats>
      )}
    </div>
  );
}

const StyledSimilarBoats = styled.div`
  padding-top: 60px;

  ${mediaMMdMax} {
    padding-top: 35px;
  }

  ${SubHeading} {
    margin-bottom: 22px;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;

  ${mediaMMdMax} {
    overflow: visible !important;
  }
`;
