import { forwardRef, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { useAtomValue } from 'jotai';
import {
  bookingDurationAtom,
  isBookingMultiDayAtom,
  selectedBookingPackageAtom,
  bookingTripTimeAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useTripCalculator } from 'hooks/queries';
import { Flex } from '../../primitives/flex';
import { dollarsNoCentsWithSymbol } from '../../../helpers/currency';
import BookingForm from './BookingForm';
import { useBoatDetails } from '../../pdp/v3/hooks';
import OutstandingOffer from './OutstandingOffer';
import BookingTerms from './BookingTerms';
import CancellationInfo from './CancellationInfo';

interface Props {
  boatId: string;
  primaryPackage: object;
  isMessageOwner?: boolean;
}

const BookingWidget = forwardRef<HTMLDivElement, Props>(
  ({ boatId, primaryPackage, isMessageOwner = false }: Props, ref) => {
    const { boatDetails } = useBoatDetails(boatId);
    const { tripCalculation, isLoading, isIdle } = useTripCalculator();
    const tripTime = useAtomValue(bookingTripTimeAtom);
    const isBookingMultiDay = useAtomValue(isBookingMultiDayAtom);
    const bookingDuration = useAtomValue(bookingDurationAtom);
    const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
    const [isExpanded, setExpanded] = useState(!!tripTime);

    return (
      <StyledBookingWidget ref={ref}>
        <OutstandingOffer boatId={boatId} />
        {!!primaryPackage && (
          <TopAmount gap="5px">
            <BoldAmount data-testid="pdp-booking-widget-package-amount-value">
              {isLoading || isIdle ? (
                <LoadingWrapper>
                  <LoadingSpinner color="#72D4BA" />
                </LoadingWrapper>
              ) : (
                dollarsNoCentsWithSymbol(tripCalculation?.pre_tax_and_fees_booking_total || 0)
              )}
            </BoldAmount>
            <Suffix>
              / {isBookingMultiDay ? bookingDuration?.label : bookingDuration?.label?.replace('hours', 'hr')} (excl.
              fees)
            </Suffix>
          </TopAmount>
        )}
        <BookingForm
          boatId={boatId}
          setExpanded={setExpanded}
          isMessageOwner={isMessageOwner}
          isExpanded={isExpanded}
          primaryPackage={primaryPackage}
        />
        {isExpanded && (
          <BookingTerms
            boatId={boatId}
            securityDepositCents={selectedBookingPackage?.security_deposit_cents}
            cancellationPolicy={boatDetails.cancellation_policy}
            fuelPolicy={selectedBookingPackage?.fuel_policy}
          />
        )}
        {tripCalculation?.free_cancelation_until &&
          dayjs(tripCalculation?.free_cancelation_until).diff(dayjs(), 'day') !== 0 && <CancellationInfo />}
        <Charged>You won&apos;t be charged yet</Charged>
      </StyledBookingWidget>
    );
  }
);

export default BookingWidget;

const LoadingWrapper = styled.div`
  width: 25px;
  transform: scale(0.7);
  padding-top: 15px;
  padding-left: 15px;
`;

const TopAmount = styled(Flex)`
  border-bottom: 1px solid rgba(219, 223, 229, 0.5);
  padding-bottom: 15px;
`;

const Charged = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  border-top: 1px solid rgba(219, 223, 229, 0.5);
  color: #5e696a;
  padding-top: 25px;
`;

const BoldAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const Suffix = styled.div`
  font-weight: 500;
  color: #8e9697;
  line-height: 30px;
`;

const StyledBookingWidget = styled.div`
  padding: 35px;
  width: 400px;
  height: fit-content;
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  margin: 0 auto;
  background: white;
  position: sticky;
  top: 50px;

  margin-top: -60px;
  z-index: 1;

  isolation: isolate;
`;
