import { useAtomValue } from 'jotai';
import { tripFinishCalculatedAtom, tripStartCalculatedAtom } from 'components/forms/bookingWidget/jotaiStore';
import { bookingOverlapsTrip } from '../utils';
import useGetExistingBookingData from './useGetExistingBookingData';

export default function useRedirectToExistingBooking(boatId) {
  // get details from calculator
  const tripStart = useAtomValue(tripStartCalculatedAtom);
  const tripFinish = useAtomValue(tripFinishCalculatedAtom);

  const { existingTrip } = useGetExistingBookingData(boatId, tripStart);
  const overlaps = bookingOverlapsTrip(existingTrip?.trip_start, existingTrip?.trip_finish, tripStart, tripFinish);

  if (existingTrip && overlaps) {
    return existingTrip?.id;
  }

  return null;
}
