import { useState } from 'react';
import styled from 'styled-components';
import { FlexAlignCenter, FlexCol, FlexColAlignCenter, FlexJustifySpaceBetween } from 'components/primitives/flex';
import { useRouter } from 'next/router';
import OutstandingOffer from 'components/forms/bookingWidget/OutstandingOffer';
import { BoatPackage } from 'swagger/models';
import { useCloseModal } from 'components/remodal/v2/hooks';
import isEmpty from 'lodash/isEmpty';
import Button from 'components/ui/Button/Button';
import { getProfilePicFromUser } from 'utils/images';
import { Badge, SubHeading } from '../common';
import BookingForm from '../../../forms/bookingWidget/BookingForm';
import { defaultPic } from '../../../../helpers';
import { useBoatDetails } from '../hooks';
import { useBreakpoint } from '../../../BreakpointProvider';
import { mediaMMdMax } from '../../../../helpers/breakpoints';

export default function MessageOwnerModal({ boatId, primaryPackage }: { boatId: string; primaryPackage: BoatPackage }) {
  const closeModal = useCloseModal();
  const router = useRouter();

  const { boatDetails } = useBoatDetails(boatId);

  const [finished, setFinished] = useState(false);
  const [booking, setBooking] = useState(null);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const onFinish = (result) => {
    setBooking(result);
    setFinished(true);
  };

  const viewConversation = () => {
    router.push(`/a/inbox/${booking.conversation_id}`);
    closeModal();
  };

  if (isEmpty(boatDetails)) {
    return null;
  }

  return (
    <Container data-testid="component-message-owner-modal">
      {finished && (
        <SuccessWrapper>
          {smallScreen && (
            <CloseWrapperFinish onClick={closeModal}>
              <CloseIcon />
            </CloseWrapperFinish>
          )}
          <SuccessIcon />
          <div>
            <SuccessMessage>Your message to {boatDetails.primary_manager.first_name} has been sent.</SuccessMessage>
          </div>
          <Button text="view conversation" onClick={viewConversation} fullWidth />
        </SuccessWrapper>
      )}
      {!finished && (
        <>
          <Header>
            {smallScreen && (
              <CloseWrapper onClick={closeModal}>
                <CloseIcon />
              </CloseWrapper>
            )}
            <InfoWrapper>
              <StyledSubHeading>
                Message {!smallScreen && <br />} {boatDetails.primary_manager.first_name}
              </StyledSubHeading>
              <ResponseTime>
                Avg. response time: <span>{boatDetails.primary_manager.response_time}</span>
              </ResponseTime>
            </InfoWrapper>
            <FlexColAlignCenter gap="12px">
              <UserImage
                src={getProfilePicFromUser(boatDetails.primary_manager)}
                onError={(e) => {
                  // @ts-ignore
                  e.target.src = defaultPic(boatDetails.primary_manager.first_name);
                }}
              />
              <Badge
                topOwner={boatDetails.primary_manager?.top_owner_badge}
                newOwner={boatDetails.primary_manager?.new_owner_badge}
              />
            </FlexColAlignCenter>
          </Header>
          <OutstandingOffer boatId={boatId} />
          <BookingForm
            onSuccess={onFinish}
            setExpanded={() => {}}
            primaryPackage={primaryPackage}
            boatId={boatId}
            isExpanded
            isModal
            isMessageOwner
          />

          <ProtectWrapper gap="15px">
            {!smallScreen && <ProtectIcon />}
            <MaxWidth>
              <span>
                To protect your payment and insurance coverage, always communicate and pay through the Boatsetter
                platform.
              </span>
            </MaxWidth>
          </ProtectWrapper>
        </>
      )}
    </Container>
  );
}

const CloseWrapper = styled.div`
  background: #f5f7f9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseWrapperFinish = styled(CloseWrapper)`
  align-self: end;
  margin-bottom: 25px;
  svg {
    width: 15px !important;
    height: 15px !important;
  }
`;

const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="1.41406" width="2" height="14" rx="1" transform="rotate(-45 0 1.41406)" fill="#5E696A" />
    <rect
      x="1.41406"
      y="11.3135"
      width="2"
      height="14"
      rx="1"
      transform="rotate(-135 1.41406 11.3135)"
      fill="#5E696A"
    />
  </svg>
);

const Container = styled(FlexCol)`
  min-width: 340px;
  .REMODAL_scroll & {
    ${mediaMMdMax} {
      padding-bottom: 124px;
      padding-top: 0;
    }
  }

  ${mediaMMdMax} {
    min-width: unset;
  }
`;

const SuccessWrapper = styled(FlexCol)`
  align-items: center;
  align-self: center;
  max-width: 340px;
  padding-top: 25px;

  ${mediaMMdMax} {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;
const SuccessMessage = styled.div`
  font-weight: 600;
  padding-top: 20px;
  font-size: 28px;
  text-align: center;
  padding-bottom: 40px;
  ${mediaMMdMax} {
    font-size: 18px;
  }
`;

const SuccessIcon = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 0C10.0854 0 0 10.1079 0 22.4999C0 34.9144 10.108 44.9999 22.5 44.9999C34.9145 44.9999 45 34.8918 45 22.4999C45.0226 10.0853 34.9145 0 22.5 0ZM22.5 41.6079C11.9623 41.6079 3.39196 33.0376 3.39196 22.4999C3.39196 11.9622 11.9623 3.39196 22.5 3.39196C33.0377 3.39196 41.608 11.9622 41.608 22.4999C41.6306 33.0376 33.0377 41.6079 22.5 41.6079ZM32.902 15.2863C33.5578 15.9647 33.5351 17.0275 32.8794 17.6833L20.51 29.7361C20.1708 30.0527 19.7638 30.211 19.3342 30.211C18.9045 30.211 18.4749 30.0527 18.1357 29.7361L12.1658 23.8567C11.4874 23.201 11.4874 22.1155 12.1432 21.4598C12.799 20.804 13.8844 20.7813 14.5402 21.4371L19.3342 26.1407L30.5276 15.2412C31.1608 14.608 32.2462 14.6079 32.902 15.2863Z"
      fill="#2A8500"
    />
  </svg>
);

const UserImage = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  ${mediaMMdMax} {
    width: 40px;
    height: 40px;
  }
`;

const InfoWrapper = styled(FlexCol)`
  max-width: 300px;
  padding-bottom: 30px;

  ${mediaMMdMax} {
    padding-bottom: 0;
    text-align: center;
  }
`;

const ResponseTime = styled.div`
  font-size: 12px;

  ${mediaMMdMax} {
    color: #9aa1a1;
  }

  span {
    font-weight: 600;
    font-size: 16px;

    ${mediaMMdMax} {
      font-size: 12px;
      color: #5e696a;
    }
  }
`;

const Header = styled(FlexJustifySpaceBetween)`
  ${mediaMMdMax} {
    position: sticky;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.08);
    z-index: 1;
    background: white;
    width: 100vw;
    transform: translateX(-24px) translateY(-24px);
    top: 0;
    padding: 15px;
    align-items: center;
  }
`;

const StyledSubHeading = styled(SubHeading)`
  margin-bottom: 0;
  ${mediaMMdMax} {
    font-size: 18px;
  }
`;

const ProtectWrapper = styled(FlexAlignCenter)`
  max-width: 330px;
  padding-top: 40px;
  ${mediaMMdMax} {
    padding-top: 20px;
  }
`;

const MaxWidth = styled.div`
  line-height: 16px;

  span {
    font-size: 12px;
  }

  ${mediaMMdMax} {
    color: rgba(94, 105, 106, 0.68);
    text-align: center;
    font-size: 11px;
  }
`;

const ProtectIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    style={{ minWidth: 36 }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#72D4BA" />
    <path
      d="M22.0371 16.091V14.1217C22.0371 12.1298 20.3285 10.5 18.2225 10.5H17.7775C15.6795 10.5 13.9629 12.1222 13.9629 14.1217V16.091C12.8503 16.2495 12 17.1549 12 18.2565V23.3043C12 24.5116 13.0411 25.5 14.3126 25.5H21.6874C22.9589 25.5 24 24.5116 24 23.3043V18.2565C24 17.1549 23.1417 16.2495 22.0371 16.091ZM22.8079 23.3043C22.8079 23.8929 22.3073 24.3682 21.6874 24.3682H14.3126C13.6927 24.3682 13.1921 23.8929 13.1921 23.3043V18.2565C13.1921 17.668 13.6927 17.1927 14.3126 17.1927H21.6874C22.3073 17.1927 22.8079 17.668 22.8079 18.2565V23.3043ZM20.845 14.1217V16.0609H15.1629V14.1217C15.1629 12.7485 16.3391 11.6318 17.7854 11.6318H18.2305C19.6689 11.6318 20.845 12.7485 20.845 14.1217ZM19.1285 20.7767C19.1285 21.3652 18.6199 21.8481 18 21.8481C17.3801 21.8481 16.8715 21.3652 16.8715 20.7767C16.8715 20.1881 17.3801 19.7052 18 19.7052C18.6199 19.7052 19.1285 20.1881 19.1285 20.7767Z"
      fill="#083170"
    />
  </svg>
);
