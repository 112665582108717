import { useBookingTrip } from 'components/booking/queryHooks';
import { useTrips } from 'components/pdp/v3/hooks';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { existingBookingAtom } from '../jotaiStore';

export default function useGetExistingBookingData(boatId, tripStart) {
  const { myTrips } = useTrips(boatId, ['pre_approved', 'initialized']);

  // get pre_approved or active inquiries with same startdate
  const booking = myTrips
    .filter((bkng) => ['initialized', 'pre_approved'].includes(bkng.state))
    .find((bkng) => {
      const tripStartMoment = dayjs(bkng.trip_start);
      return tripStartMoment.isSame(tripStart, 'day');
    });

  const { bookingTrip: existingTrip, isLoading } = useBookingTrip({ enabled: !!booking?.id }, booking?.id);

  const setExistingBooking = useSetAtom(existingBookingAtom);
  if (!isLoading && existingTrip) {
    setExistingBooking(existingTrip);
  }

  return {
    existingTrip,
  };
}
