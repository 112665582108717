import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import { Section, SubHeading, ToggleLink } from './common';
import { CheckIcon } from './icons';
import { useBoatDetails } from './hooks';
import { Flex, FlexWrap } from '../../primitives/flex';
import { useBreakpoint } from '../../BreakpointProvider';

export default function Features({ boatId }: { boatId: string }) {
  const { boatDetails } = useBoatDetails(boatId);

  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;

  const [featuresExpanded, setFeaturesExpanded] = useState(false);
  const MAX_FEATURES = smallScreen ? 5 : 15;

  const boatFeatures = useMemo(() => sortFeatures(boatDetails.features ?? []), [boatDetails?.features?.length]);

  return (
    <div data-testid="pdp-features-amenities">
      {boatFeatures.length > 0 && (
        <Section>
          <SubHeading>Amenities</SubHeading>
          <FeaturesContainer>
            {boatFeatures.slice(0, featuresExpanded ? 100 : MAX_FEATURES).map((feature) => (
              <Feature key={feature.id} data-testid="pdp-features-amenities-feature">
                <CheckIcon />
                <span>{feature.name}</span>
              </Feature>
            ))}
          </FeaturesContainer>
          {boatFeatures.length > MAX_FEATURES && (
            <ToggleLink onClick={() => setFeaturesExpanded(!featuresExpanded)} data-testid="pdp-feature-toggle-link">
              See {featuresExpanded ? 'less' : 'all'}
            </ToggleLink>
          )}
        </Section>
      )}
    </div>
  );
}

const FeaturesContainer = styled(FlexWrap)`
  padding-top: 25px;
  gap: 20px 0;

  ${mediaMMdMax} {
    display: flex;
    flex-direction: column;
  }
`;

const Feature = styled(Flex)`
  gap: 15px;

  ${mediaMLgMin} {
    width: 33%;
  }
`;

const featuresOrderMap = [
  'Bathroom',
  'Stereo',
  'Stereo AUX input',
  'Bluetooth Audio',
  'Floating mat',
  'Inflatable toys',
  'Floating island',
  'Cooler / Ice chest',
  'Air conditioning',
  'Swim ladder',
  'Jet ski',
  'Paddleboards',
  "Children's life jackets",
  'Kayaks',
  'Snorkeling gear',
  'Wakeboard',
  'Waterskis',
  'Fishing gear',
  'Seabob',
  'Shower',
  'WiFi',
  'Deck shower',
  'Refrigerator',
  'Anchor',
  'Tender',
  'Fish finder',
  'Livewell / Baitwell',
  'Grill',
  'Bow thruster',
  'Depth finder',
  'GPS',
  'Radar',
  'Sonar',
  'VHF radio',
];

const cleanStr = (str: string) => str.toLowerCase().replace(/\s|\/|'/g, '');
const sortingArray = featuresOrderMap.map(cleanStr);

const sortFeatures = (features) =>
  features.sort((a, b) => sortingArray.indexOf(cleanStr(a.name)) - sortingArray.indexOf(cleanStr(b.name)));
